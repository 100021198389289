import React from 'react';
import {Redirect} from 'react-router-dom';

// import {createRoutes} from '@crema/utility/Utils';
import {authRouteConfig} from './auth/config';
import {dashboardConfig} from './dashboard/config'
import {errorRouteConfig} from './error/config'
import {initialUrl} from 'shared/constants/AppConst';
import AppRefresh from 'components/AppRefresh';
import RegisterTemple from 'containers/RegisterTemple';
import { staffConfig } from './staff/config';
import UserSwitcher from 'containers/UserSwitcher';

const routeConfigs = [
  ...authRouteConfig,
  ...errorRouteConfig,
  ...dashboardConfig,
  ...staffConfig
];


const routes = [
  // ...createRoutes(routeConfigs),
  ...routeConfigs,
  {
    path: '/',
    exact: true,
    component: () => <Redirect to={initialUrl} />,
  },
  {
    path: '/refresh',
    exact: true,
    component: () => <AppRefresh />
  },
  {
    path: '/register-temple',
    exact: true,
    auth: ['user'],
    component: () => <RegisterTemple/>
  },
  {
    path: '/user-switch',
    exact: true,
    auth: ['user', 'staff'],
    component: () => <UserSwitcher/>
  },
  {
    component: () => <Redirect to='/404' />,
  },
];

export default routes;
