import axios, {AxiosRequestConfig, AxiosResponse} from 'axios'
import { baseUrl } from 'shared/constants/AppConst'
import camelCaseKeys from 'camelcase-keys'
import snakeCaseKeys from 'snakecase-keys'
import { getAuthToken } from 'utils/auth'

const defaultConfig: AxiosRequestConfig= {
    baseURL: baseUrl,
    headers: {
        'Content-Type': 'application/json'
    },
}

export const apiInstance = axios.create(defaultConfig)
export const uploadInstance = axios.create({baseURL: baseUrl})

const requestInterceptor = (config: AxiosRequestConfig) => {
    const token = getAuthToken()
    if (config?.data)
        config.data = snakeCaseKeys(config.data)
    config.headers.Authorization = token ?  `Bearer ${token}` : '';
    return config
}

const responseInterceptor = (response: AxiosResponse) => {
    if (response?.data)
        response.data = camelCaseKeys(response.data, {deep: true, exclude: [/^.*_\d.*$/]})
    return response
}

const responseErrInterceptor = (error: any) => {
    if(error?.response?.status === 500){
        error.response.data = {detail: '500 Internal Server Error Occured'}
    }
    else if(error?.response?.data)
        error.response.data = camelCaseKeys(error.response.data, {deep: true})
    throw error
}

apiInstance.interceptors.request.use(requestInterceptor)

apiInstance.interceptors.response.use(responseInterceptor, responseErrInterceptor)

uploadInstance.interceptors.request.use((config: AxiosRequestConfig) => {
    const token = getAuthToken()
    config.headers.Authorization = token ?  `Bearer ${token}` : '';
    return config
})
uploadInstance.interceptors.response.use(responseInterceptor, responseErrInterceptor)


export const deleteWithUrl = {
    delete: async(url: string) => await apiInstance.delete(url)
}
