import { ReactNode } from "react";

export interface NavItemProps {
  id: string;
  messageId: string;
  title: string;
  icon?: string;
  exact?: boolean;
  url?: string;
  type?: string;
  count?: number;
  color?: string;
  auth?: string[];
  children?: NavItemProps[] | NavItemProps;
}

export const dashboardConfig: NavItemProps[] = [
  {
    id: 'temple',
    title: 'Manage',
    type: 'group',
    messageId: 'common.manage',
    auth: ['user'],
    children: [
      {
        id: 'bookings',
        title: 'Bookings',
        messageId: 'sidebar.bookings',
        type: 'item',
        icon: 'list-alt',
        url: '/dashboard/bookings',
      },
      {
        id: 'donationReceipts',
        title: 'Donation Receipts',
        messageId: 'sidebar.donationReceipt',
        type: 'item',
        icon: 'list-alt',
        url: '/dashboard/donation-receipts',
      },
      {
        id: 'offeringCategories',
        title: 'Offering Categories',
        messageId: 'sidebar.offeringCategories',
        type: 'item',
        icon: 'category',
        url: '/dashboard/offering-categories',
      },
      {
        id: 'offerings',
        title: 'Offerings',
        messageId: 'sidebar.offerings',
        type: 'item',
        icon: 'notifications-none-icon',
        url: '/dashboard/offerings',
      },
      // {
      //   id: 'offeringCompletion',
      //   title: 'Offering Completion',
      //   messageId: 'sidebar.offerings.completion',
      //   type: 'item',
      //   icon: 'check_circle',
      //   url: '/dashboard/offerings-completion',
      // },
      {
        id:'donationCategories',
        title:'donationCategories',
        messageId:'sidebar.donationCategory',
        type:'item',
        icon:'category',
        url:'/dashboard/donation-categories'

      },
      {
        id:'donation',
        title:'Donation',
        messageId:'sidebar.donation',
        type:'item',
        icon:'money',
        url:'/dashboard/donations'
      },
      {
        id: 'deities',
        title: 'Deities',
        messageId: 'sidebar.deities',
        type: 'item',
        icon: 'flare',
        url: '/dashboard/deities',
      },
      {
        id: 'profile',
        title: 'Profile',
        messageId: 'sidebar.profile',
        type: 'item',
        icon: 'edit',
        url: '/dashboard/profile',
      },
      {
        id: 'reports',
        title: 'Reports',
        messageId: 'sidebar.report',
        type: 'item',
        icon:'book',
        url: '/dashboard/reports',
      },
    ]
  },
  {
    id: 'dashboard',
    title: 'dashboard',
    type: 'item',
    messageId: 'sidebar.dashboard',
    icon: 'space_dashboard',
    url: '/staff/dashboard',
    auth: ['staff']
  },
  {
    id: 'other',
    title: 'Other',
    messageId: 'common.other',
    type: 'group',
    auth: ['user'],
    children: [
      {
        id: 'register-temple',
        title: 'Register Temple',
        messageId: 'sidebar.temple.register',
        type: 'item',
        icon: 'notes',
        url: '/register-temple',
      },
    ]
  },
  // {
  //   id: 'user-switcher',
  //   title: 'Use as Staff',
  //   messageId: 'sidebar.use.staff',
  //   type: 'item',
  //   icon: 'swap_horiz',
  //   url: '/user-switch'
  // }
];


