import {store} from 'store/reduxStore'
import { selectAuth, AuthState } from 'store/reducers/auth/authSlice'
import { setAuthToken } from 'utils/auth'


let currentState : AuthState

export const subscribeAuth = () => {
    let previousState = currentState
    currentState = selectAuth(store.getState())
    if (previousState && previousState.token !== currentState.token){
        const token = currentState.token
        setAuthToken(token)
    }
}