import { FormControlLabel, Switch } from '@material-ui/core';
import { IRow, MemoizedBasicTable, StringNumberArrayType } from 'components/Table/BasicTable';
import React, { useCallback, useMemo } from 'react';
import { useAppDispatch } from 'store/hooks';
import { offeringUpdateThunk } from 'store/reducers/temple/offeringSlice';
import { IOfferingResponse, IOfferingTimeResponse } from 'types/api/temple';
import { capStr } from 'utils/fns';
import { fmtBlockPeriod, getTimeChipText, offeringTimeToForm } from 'utils/temple/offering';

interface OfferingTableProps {
    editHandler?: ((id: string | number) => void | Promise<any>) & Function,
    deleteHandler?: ((id: string | number) => void | Promise<any>) & Function,
    deletingIds?: StringNumberArrayType,
    offerings: IOfferingResponse[]
}

const getRows = (offerings: IOfferingResponse[],handleToggle:(offering:IOfferingResponse)=>void): IRow[] => {
    let rows: IRow[] = []
    offerings.forEach(offering => {
        rows.push({
            id: offering.id,
            values: [
                offering.name,
                capStr(offering.offeringType),
                offering.category.name,
                offering.price,
                offering.offeringTimes?.length === 0 ? offering.perDayLimit ?? 'No limit' : 'N/a',
                offering.blockPeriod && offering.blockUnit ? fmtBlockPeriod(offering.blockPeriod, offering.blockUnit) : 'N/a',
                offering.offeringTimes ? (
                    <React.Fragment>
                        {offering.offeringTimes.map((offeringTime: IOfferingTimeResponse) => (
                            <React.Fragment key={offeringTime.id}>
                                {getTimeChipText(offeringTimeToForm(offeringTime))}
                                <br />
                            </React.Fragment>
                        ))}
                    </React.Fragment>
                ) : 'N/a',
                (offering.isDakshinaRequired && offering.minDakshinaAmount) ? offering.minDakshinaAmount : "N/A",

                <React.Fragment>
                    <FormControlLabel
                        label={""}
                        checked={offering.isDakshinaRequired ? offering.isDakshinaRequired : false}
                        onChange={()=>handleToggle(offering)}
                        control={
                            <Switch
                                name={''}
                            />}
                    />
                </React.Fragment>

            ]
        })
    })
    return rows
}

const OfferingTable: React.FC<OfferingTableProps> = ({ editHandler, deleteHandler, deletingIds, offerings }) => {
    const dispatch = useAppDispatch()
    
    const handleToggle = useCallback(async (offering:IOfferingResponse)=>{
        await dispatch(offeringUpdateThunk({
            url: offering.url,
            data: {isDakshinaRequired:!offering.isDakshinaRequired}
        }))
    },[dispatch])
    const rows = useMemo(() => getRows(offerings,handleToggle), [offerings,handleToggle])
    return (
        <MemoizedBasicTable
            rows={rows}
            headRow={[
                'common.name',
                'offering.offeringType',
                'offeringCategory',
                'common.price',
                'offering.perDayLimit',
                'offering.blockPeriod',
                'offering.time',
                'offering.minDakshinaAmount',
                'offering.isDakshinaRequired',

            ]}
            editHandler={editHandler}
            deleteHandler={deleteHandler}
            deletingIds={deletingIds}
        />
    );
};

export default OfferingTable;