import { CancelToken } from 'axios'
import { apiInstance } from '../client'

const userAPI = {
    create: async(payload: CreateUserRequestType) => apiInstance.post<ILoginResponse>('/user/create/', payload),
    login: async (payload : ILoginRequest) => await apiInstance.post<ILoginResponse>('/user/auth/', payload),
    logout: async () => await apiInstance.delete('/user/logout/'),
    getUser: async() => await apiInstance.get('/user/'),
    updateUser: async(payload: Partial<UserResponseType>) => await apiInstance.patch<UserResponseType>('/user/', payload),
    listUser: async(params: {email?: string, phone?: string}, cancelToken?: CancelToken) => 
        apiInstance.get<PublicUserResponseType[]>('/user/user/', {params: params, cancelToken: cancelToken})
}

export default userAPI