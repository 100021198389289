
import { CommonTableHeadCell, CommonTableOrder } from 'components/Table/common/types';
import NormalTableHead from 'components/Table/NormalTable/NormalTableHead';
import React from 'react'
import { IOffferingFinancialRow } from 'types/api/offeringFinancial';



const headCells: CommonTableHeadCell<IOffferingFinancialRow>[] = [
  { id: 'deityName', align: 'left', disablePadding: false, label: 'Deity', hasSorting: false },
  { id: 'offeringName', align: 'left', disablePadding: false, label: 'Offering', hasSorting: false },
  { id: 'rate', align: 'left', disablePadding: false, label: ' Rate', hasSorting: false },
  { id: 'count', align: 'left', disablePadding: false, label: 'Count', hasSorting: false },
  { id: 'amount', align: 'left', disablePadding: false, label: 'Amount', hasSorting: false },
  { id: 'dakshina', align: 'left', disablePadding: false, label: ' Dakshina', hasSorting: false },
]

interface UsersTableHeadProps {
  onRequestSort: (property: keyof IOffferingFinancialRow) => void;
  order: CommonTableOrder;
  orderBy: keyof IOffferingFinancialRow;
}
const ReportTableHead = (props:UsersTableHeadProps) => {
  const { order, orderBy, onRequestSort } = props;
return (
  <NormalTableHead 
  headCells={headCells} 
  orderBy={orderBy} 
  order={order} 
  sortHandler={onRequestSort} 
  />
)
}

export default ReportTableHead