import React, {useContext} from 'react';
import {useAppSelector} from 'store/hooks'
import AppContext from '@crema/utility/AppContext';
import Layouts from './Layouts';
import useStyles from 'shared/jss/common/common.style';
import AppContextPropsType from 'types/crema/AppContextPropsType';
import { selectUser } from 'store/reducers/auth/authSlice'
import AuthLayout from 'hoc/Layouts/Auth/Auth'

interface CremaLayoutProps {}

const CremaLayout: React.FC<CremaLayoutProps> = () => {
  useStyles();
  const {navStyle} = useContext<AppContextPropsType>(AppContext);
  const user = useAppSelector(selectUser)
  const AppLayout = Layouts[navStyle];
  return (
    <>
      {user ? (
        <AppLayout />
      ) : (
        <AuthLayout />
      )}
    </>
  );
};

export default React.memo(CremaLayout);
