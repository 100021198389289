import { parseISO, format as dateFormat, parse as parseDate, subDays } from "date-fns"
import { apiDateFormat, apiTimeFormat, defaultDateFormat, defaultDateTimeFormat, defaultTimeFormat, weekDays } from "shared/constants/AppConst"
import { ImageSizes } from "shared/constants/AppEnum"
import { WeeklyDaysFlagType, WeeklyDayType } from "types/store/temple"

export const updateState = <T, K extends keyof T>(state: T, key: K, value: string | object) => {
  return typeof value === 'object' ? (
    { ...state, [key]: { ...state[key], ...value } }
  ) : (
    { ...state, [key]: value }
  )
}

export const setLocalStorageItem = (key: string, item: any) => {
  if (item) {
    localStorage.setItem(key, item)
  } else {
    localStorage.removeItem(key)
  }
}

export const getLocalStorageItem = (key: string) => localStorage.getItem(key)

export const getImage = (image: ImageResponse, size: ImageSizes): string => {
  if (size === ImageSizes.LARGE && image?.large) return image.large
  if (size >= ImageSizes.MEDIUM && image?.medium) return image.medium
  if (size >= ImageSizes.SMALL && image?.small) return image.small
  return image.full
}

export const arrayToString = (obj: any): string => {
  if (typeof obj === 'string') return obj
  if (Array.isArray(obj) && obj.every(item => typeof item === "string")) return obj.join(', ')
  return ''
}

// export const getKeyValue = <T extends object, U extends keyof T>(key: U) => (obj: T) =>
//   obj[key];
export const capStr = (str: string): string =>
  str[0].toUpperCase() + str.substr(1).toLowerCase()


export const weekDayWithFlag = (except: WeeklyDayType[] = []): WeeklyDaysFlagType => {
  let result: Partial<WeeklyDaysFlagType> = {}
  weekDays.forEach(day => result[day] = except.indexOf(day) !== -1)
  return result as WeeklyDaysFlagType
}


export const genNumArray = (n: number, offset: number = 0) => {
  return Array.from(Array(31), (e, i) => offset !== 0 ? i + offset : i)
}


export const genBooleanArray = (
  n: number, flag: boolean = false, except: (number | string)[] = []
): { [key: string]: boolean } => {
  let result: { [key: string]: boolean } = {}
  for (let i = 1; i <= n; i++) result[i] = except.indexOf(i) !== -1 ? !flag : flag
  return result
}

// export const genBooleanArray = (n: number, flag: boolean = false) : {[key: string]: boolean} => {
//   let result : StringIndexObj = {}
//   // for (let i=0; i<n; i++) result[i+1] = flag
//   return genNumArray(n, 1).reduce((prev, current) => {
//     prev[current] = flag
//     return prev
//   }, result)
// }

export const getArrayDiff = (a: (string | number)[], b: (string | number)[]) =>
  a.filter(item => b.indexOf(item) === -1)

export const getObjectArrayDiff = <T>(a: T[], b: T[]) =>
  a.filter(itemA => !b.some(itemB => {
    for (let key in itemA)
      if (itemA[key] !== itemB[key])
        return false
    return true
  }))

export const isObjectArray = <T>(arr: T[]) =>
  arr.some(item => typeof item === 'object' && item !== null && !Array.isArray(item))

export const isArrayOfArray = <T>(arr: T[]) =>
  arr.some(item => Array.isArray(item))


export const dateTimeToString = (date: Date): string => dateFormat(date, defaultDateTimeFormat)

export const isoDateToFormattedDateTime = (date: string) => {
  try {
    return dateTimeToString(parseISO(date))
  }
  catch (err) {
    return 'Invalid ISO String'
  }
}

export const isoDateToDate = (date: string): Date | boolean => {
  try {
    return parseISO(date)
  }
  catch (err) {
    return false
  }
}

export const apiDateToDate = (date: string) => {
  try {
    return parseDate(date, apiDateFormat, new Date())
  } catch (err) {
    throw err
  }
}

export const apiDateToFormattedDate = (date: string) => {
  try {
    return dateFormat(apiDateToDate(date), defaultDateFormat)
  }
  catch (err) {
    return 'Invalid Date'
  }
}

export const apiTimeToDate = (time: string) => {
  try {
    return parseDate(time, apiTimeFormat, new Date())
  }
  catch (err) {
    throw err
  }
}

export const apiTimeToFormattedTime = (time: string) => {
  try {
    return dateFormat(apiTimeToDate(time), defaultTimeFormat)
  }
  catch (err) {
    return 'Invalid Time'
  }
}

export const dateToDateString = (date: Date) => dateFormat(date, apiDateFormat)

export const apiFromToTimeToFormattedTime = (from: string, to?: string): string => {
  try {
    let times: string[] = [apiTimeToFormattedTime(from)]
    if (to)
      times.push(apiTimeToFormattedTime(to))
    return times.join(' - ')
  }
  catch (err) {
    return 'Invalid Time'
  }
}

export const fromToTimeToFormattedTime = (from: Date, to?: Date): string => {
  let times: string[] = [dateFormat(from, defaultTimeFormat)]
  if (to)
    times.push(dateFormat(to, defaultTimeFormat))
  return times.join('-')
}

export const timeStringToUTCDate = (time: string, decrement: number = 0) => {
  return decrement > 0 ?
    parseDate(`${time} +00:00`, `${apiTimeFormat} xxx`, subDays(new Date(), decrement)) :
    parseDate(`${time} +00:00`, `${apiTimeFormat} xxx`, new Date())
}

export const dateToUTCTimeString = (date: Date): string => 
  `${date.getUTCHours()}:${date.getUTCMinutes()}`

export function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}


export const areEqualUsingJSON = (obj1: any, obj2: any) => JSON.stringify(obj1) === JSON.stringify(obj2)