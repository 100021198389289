import React, { MouseEventHandler, useEffect, useState } from 'react';
import Box from '@material-ui/core/Box';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import IntlMessages from '@crema/utility/IntlMessages';
import Paper from '@material-ui/core/Paper'
import EnhancedTableToolbar from 'components/Table/EnhancedTableToolbar';
import { RouteComponentProps } from 'react-router';
import { apiStatus } from 'shared/constants/AppEnum';
import LinearProgress from '@material-ui/core/LinearProgress';
import { IDonationCategoryResponse } from 'types/api/temple';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import RedButton from 'components/Button/RedButton';
import { donationCategoryDeleteThunk, donationCategoryListThunk, selectCurrentTempleDonationCategories, selectDonationCategoryStatus } from 'store/reducers/temple/donationCategorySlice';
import DonationCategoryTable from 'components/Temple/DonationCategoryTable';




const ListDonationCategory: React.FC<RouteComponentProps> = ({ history, location }) => {
    const dispatch = useAppDispatch()
    const { pathname } = location
    const donationCategories = useAppSelector(selectCurrentTempleDonationCategories)
    const api = useAppSelector(selectDonationCategoryStatus)
    const [deleteItem, setDeleteItem] = useState<IDonationCategoryResponse | null>(null)
    const [dialogOpen, setDialogOpen] = useState(false)
    const [deleteIds, setDeleteIds] = useState<(string|number)[]>([])
    useEffect(() => {
        dispatch(donationCategoryListThunk({}))
    }, [dispatch])
    const addNewHandler: MouseEventHandler<HTMLButtonElement> = () => history.push(`${pathname}/add`)
    const editHandler = (id: string|number) => history.push(`${pathname}/edit/${id}`)
    const handlerOpenDialog = (id: string|number) => {
        let item = donationCategories.find(oc => oc.id===id)
        if (item) {
            setDeleteItem(item)
            setDialogOpen(true)
        }
    }
    const handlerCloseDialog = (canDelete?: boolean) => {
        if (canDelete && deleteItem){
            setDeleteIds([...deleteIds, deleteItem.id])
            dispatch(donationCategoryDeleteThunk(deleteItem))
            .finally(() => {
                setDeleteIds(deleteIds.filter(id => id !== deleteItem.id))
                setDeleteItem(null)
            })
        }
        setDialogOpen(false)
    }
    return (
        <Box>
            <Paper style={{width: 'max-content'}}>
                <EnhancedTableToolbar
                    title={<IntlMessages id={'donation.category.title'} />}
                    showFilter={false}
                    addNew={{ handle: addNewHandler }}
                />
                {api.status === apiStatus.LOADING && <LinearProgress />}
                <DonationCategoryTable
                    donationCategories={donationCategories}
                    editHandler={editHandler}
                    deleteHandler={handlerOpenDialog}
                    deletingIds={deleteIds}
                />
            </Paper>
            <Dialog open={Boolean(dialogOpen && deleteItem)}>
                <DialogTitle>{`Are you sure you want to delete ${deleteItem?.name}?`}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Warning: All offerings under this category will be also deleted
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="primary" onClick={() => handlerCloseDialog(false)}>
                        <IntlMessages id="common.cancel" />
                    </Button>
                    <RedButton  variant="outlined" onClick={() => handlerCloseDialog(true)}  autoFocus>
                        <IntlMessages id="common.delete" />
                    </RedButton>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default ListDonationCategory