import React, { useEffect, useState } from 'react'
import IntlMessages from '@crema/utility/IntlMessages'
import Box from '@material-ui/core/Box'
import GoBack from 'components/GoBack'
import { RouteConfigComponentProps } from 'react-router-config'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { offeringCategoryUpdateThunk} from 'store/reducers/temple/offeringCategorySlice'
import FitContentPaper from 'components/Paper/FitContentPaper'
import { FormikHelpers } from 'formik'
import { unwrapResult } from '@reduxjs/toolkit'
import { getEditedPayload, getFieldErrors } from 'utils/api'
import { IDonationCategory} from 'types/store/temple'
import { donationCategoryRetrieveThunk, selectDonationCategoryById, selectDonationCategoryStatus } from 'store/reducers/temple/donationCategorySlice'
import FormDonationCategory from './FormDonationCategory'

interface RouteParams {
    id: string | undefined
}

const EditDonationCategory: React.FC<RouteConfigComponentProps<RouteParams>> = (props) => {
    const { id } = props.match.params
    const api = useAppSelector(selectDonationCategoryStatus)
    const donationCategory = useAppSelector(state => selectDonationCategoryById(state, id ? id : ''))
    const [isSuccess, setIsSuccess] = useState(false)
    const [fetched, setFetched] = useState(false)
    const [initialValues, setInitialValues] = useState<IDonationCategory>({name: ''})
    const dispatch = useAppDispatch()
    useEffect(() => {
        if (donationCategory){
            setInitialValues({name: donationCategory.name})
        }
        if(!fetched){
            if (donationCategory)
                dispatch(donationCategoryRetrieveThunk({url: donationCategory.url}))
            else 
                dispatch(donationCategoryRetrieveThunk({ id: id ? id : ''}))
            setFetched(true)
        }
    }, [dispatch, donationCategory, id, fetched])

    const handleSubmit = (data: IDonationCategory, actions: FormikHelpers<IDonationCategory>) => {
        setIsSuccess(false)
        let payload = getEditedPayload(initialValues, data)
        if (payload && donationCategory?.url){
            dispatch(offeringCategoryUpdateThunk({ data: payload, url: donationCategory.url }))
                .then(unwrapResult)
                .then(() => setIsSuccess(true))
                .catch(err => {
                    Object.entries(getFieldErrors(data, err)).forEach(([key, value]) => 
                        actions.setFieldError(key, value))
                })
                .finally(() => {
                    actions.setSubmitting(false)
                })
        } else{
            actions.setSubmitting(false)
        }
    }
    const handleSuccess = () => isSuccess ? 'Donation category updated' : ''

    return (
        <GoBack {...props}>
            <FitContentPaper>
                <Box component="h5" mb={4}><IntlMessages id="donation.category.edit.title" /></Box>
                <FormDonationCategory
                    buttonLabelId="common.update"
                    api={api}
                    initialValues={initialValues}
                    onSubmitCb={handleSubmit}
                    isSuccess={isSuccess}
                    successCb={handleSuccess}
                />
            </FitContentPaper>
        </GoBack>
    )
}

export default EditDonationCategory