import React, { useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { CremaTheme } from 'types/crema/AppContextPropsType';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectCurrentTemple, selectTempleReadApi, selectTempleUpdateApi, templeRetrieveThunk } from 'store/reducers/temple/templeSlice';
import { apiStatus } from 'shared/constants/AppEnum';
import Error404 from 'components/ErrorPage/Error404';
import Loader from '@crema/core/Loader';
import TempleImg from './TempleImg';
import TempleForm from './TempleForm';
import BankAccount from './BankAccount';
import TempleLocation from './TempleLocation';

const useStyles = makeStyles((theme: CremaTheme) => (
    createStyles({
        paper: {
            width: "100%",
            overflow: 'hidden'
        },
    })
))

const TempleProfile = () => {
    const classes = useStyles()
    const temple = useAppSelector(selectCurrentTemple)
    const readApi = useAppSelector(selectTempleReadApi)
    const updateApi = useAppSelector(selectTempleUpdateApi)
    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(templeRetrieveThunk())
    }, [dispatch])
    return (
        readApi.status === apiStatus.LOADING ?
            <Loader /> :
            temple ? (
                <Grid container spacing={6}>
                    <Grid item xs={12} sm={7}>
                        <Paper className={classes.paper}>
                            <TempleImg temple={temple} />
                            <Box m={8}>
                                <TempleForm temple={temple} api={updateApi} />
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        <BankAccount temple={temple} />
                    </Grid>
                    <Grid item xs={12} sm={7}>
                        <Paper className={classes.paper}>
                            <TempleLocation temple={temple} />
                        </Paper>
                    </Grid>
                </Grid>
            ) : <Error404 />
    );
};

export default TempleProfile;
