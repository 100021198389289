import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, DeepPartial, PayloadAction } from '@reduxjs/toolkit'
import { apiStatus } from 'shared/constants/AppEnum'
import templeAPI from 'api/temple'
import {templeAPI as staffTempleApi} from 'api/admin'
import { AppState } from 'store/reduxStore'
import { ITempleResponse } from 'types/api/temple'
import { getLocalCurrentTemple, removeLocalCurrentTemple, setLocalCurrentTemple } from 'utils/temple'
import { resetState as resetOrderState } from './orderSlice'
import { resetState as resetReceiptState } from './receiptSlice'
import { TempleImgType } from 'types/store/temple'
import { IApiState } from 'store/types'
import { extractAxiosHeaderLink } from 'utils/api'
import { StaffTempleParamsType } from 'api/admin/types'

const templesAdapter = createEntityAdapter<ITempleResponse>({
    sortComparer: (a, b) => a.name.localeCompare(b.name),
})

type TempleStateType = {
    createApi: IApiState,
    readApi: IApiState,
    listApi: IApiState,
    deleteApi: IApiState,
    updateApi: IApiState,
    uploadApi: IApiState,
    activationApi: IApiState,
    currentTemple: string | number,
    imgUploadApi: {
        cover: IApiState,
        display: IApiState
    },
}

const addOnInitialState: TempleStateType = {
    createApi: {
        status: apiStatus.IDLE,
        error: null,
    },
    readApi: {
        status: apiStatus.IDLE,
        error: null,
    },
    listApi: {
        status: apiStatus.IDLE,
        error: null,
    },
    deleteApi: {
        status: apiStatus.IDLE,
        error: null,
    },
    updateApi: {
        status: apiStatus.IDLE,
        error: null,
    },
    uploadApi: {
        status: apiStatus.IDLE,
        error: null,
    },
    activationApi: {
        status: apiStatus.IDLE,
        error: null,
    },
    imgUploadApi: {
        cover: {
            status: apiStatus.IDLE,
            error: null,
        },
        display: {
            status: apiStatus.IDLE,
            error: null,
        },
    },
    currentTemple: getLocalCurrentTemple(),
}

export const createTempleThunk = createAsyncThunk(
    'temple/create',
    async(payload: ITempleResponse, {rejectWithValue}) => {
        try {
            await templeAPI.createTemple(payload)
            return true
        } catch (err:any){
            return rejectWithValue(err.response.data)
        }
    }
)

export const templeListThunk = createAsyncThunk(
    'temple/list',
    async (_, { rejectWithValue }) => {
        try {
            const response = await templeAPI.list()
            return response.data
        } catch (err:any) {
            return rejectWithValue(err.response.data)
        }
    })

export const staffTempleListThunk = createAsyncThunk(
    'staff/temple/list',
    async(payload: StaffTempleParamsType | UrlType, { rejectWithValue }) => {
        try{
            const response = await staffTempleApi.list(payload)
            return {data: response.data, nextLink: extractAxiosHeaderLink(response, 'next')}
        } catch (err:any) {
            return rejectWithValue(err.response.data)
        }
    }
)

export const staffTempleRetrieveThunk = createAsyncThunk(
    'staff/temple/retrive',
    async(payload: UrlType, { rejectWithValue }) => {
        try{
            const response = await staffTempleApi.get(payload.url)
            return response.data
        } catch (err:any) {
            return rejectWithValue(err.response.data)
        }
    }
)

export const staffTempleUpdateThunk = createAsyncThunk(
    'staff/temple/update',
    async (payload: PayloadWithUrl<Partial<ITempleResponse>>, { rejectWithValue }) => {
        try {
            const response = await staffTempleApi.update(payload.url, payload.data)
            return response.data
        } catch (err:any) {
            return rejectWithValue(err.response.data)
        }
    }
)

export const templeActivationThunk = createAsyncThunk(
    'staff/temple/approve',
    async(payload: IdAndUrlType & { shouldActivate: boolean}) => {
        payload.shouldActivate ? 
            await staffTempleApi.activate(payload.url):
            await staffTempleApi.deactive(payload.url)
    }
)

export const templeRetrieveThunk = createAsyncThunk(
    'temple/retrieve',
    async (payload: IdOrUrlType | undefined, { rejectWithValue }) => {
        try {
            let params
            if (payload) {
                if ('id' in payload && payload.id)
                    params = { itemId: payload.id }
                else if ('url' in payload)
                    params = { url: payload.url }
                else
                    return rejectWithValue({ detail: 'Identifier not provided' })
            }
            const response = params ? await templeAPI.get(params) : await templeAPI.get()
            return response.data
        } catch (err:any) {
            return rejectWithValue(err.response.data)
        }
    }
)

export const templeUpdateThunk = createAsyncThunk(
    'temple/update',
    async (payload: PayloadWithUrl<DeepPartial<ITempleResponse>>, { rejectWithValue }) => {
        try {
            const response = await templeAPI.update(payload.url, payload.data)
            return response.data
        } catch (err:any) {
            return rejectWithValue(err.response.data)
        }
    }
)

export const templeDeleteThunk = createAsyncThunk(
    'temple/delete',
    async(payload: IdAndUrlType) => {
        await templeAPI.delete(payload.url)
        return payload.id
    }
)

export const uploadImgThunk = createAsyncThunk(
    'temple/img/upload',
    async (payload: IdAndUrlType & { file: File } & { imgType: TempleImgType }, { rejectWithValue }) => {
        try {
            const response = await templeAPI.uploadImg(payload.url, payload.imgType, payload.file)
            return response.data
        } catch (err:any) {
            return rejectWithValue(err.response.data)
        }
    }
)

export const setTempleThunk = createAsyncThunk(
    'temples/set',
    async (payload: IdType, { dispatch }) => {
        dispatch(resetOrderState())
        dispatch(resetReceiptState())
        return payload
    }
)

const templeSlice = createSlice({
    name: 'temples',
    initialState: templesAdapter.getInitialState(addOnInitialState),
    reducers: {
        resetState(state){
            templesAdapter.removeAll(state)
            state.imgUploadApi.cover = {
                status: apiStatus.IDLE,
                error: null
            }
            state.imgUploadApi.display = {
                status: apiStatus.IDLE,
                error: null
            }
            state.listApi = {
                status: apiStatus.IDLE,
                error: null
            }
            state.readApi = {
                status: apiStatus.IDLE,
                error: null
            }
            state.updateApi = {
                status: apiStatus.IDLE,
                error: null
            }
            state.uploadApi = {
                status: apiStatus.IDLE,
                error: null
            }
             state.currentTemple = ''
            removeLocalCurrentTemple()
        },
        upsertTemple(state, action: PayloadAction<ITempleResponse|ITempleResponse[]>){
            Array.isArray(action.payload) ?
                templesAdapter.upsertMany(state, action.payload) :
                templesAdapter.upsertOne(state, action.payload)
        }
    },
    extraReducers: (builder) => {
        builder.addCase(setTempleThunk.fulfilled, (state, action) => {
            if (action.payload.id !== state.currentTemple) {
                state.currentTemple = action.payload.id
                setLocalCurrentTemple(action.payload.id)
            }
        })
        builder.addCase(createTempleThunk.pending, (state, action) => {
            state.createApi = {
                status: apiStatus.LOADING,
                error: null
            }
        })
        builder.addCase(createTempleThunk.fulfilled, (state, action) => {
            state.createApi = {
                status: apiStatus.SUCCEEDED,
                error: null
            }
        })
        builder.addCase(createTempleThunk.rejected, (state, action) => {
            state.createApi = {
                status: apiStatus.FAILED,
                error: action.payload
            }
        })
        builder.addCase(templeListThunk.pending, (state, action) => {
            state.listApi = {
                status: apiStatus.LOADING,
                error: null
            }
        })
        builder.addCase(templeListThunk.fulfilled, (state, action) => {
            state.listApi = {
                status: apiStatus.SUCCEEDED,
                error: null
            }
            templesAdapter.upsertMany(state, action.payload)
            if (!state.currentTemple && Object.keys(action.payload).length > 0) {
                state.currentTemple = action.payload[0].id
                setLocalCurrentTemple(action.payload[0].id)
            }
        })
        builder.addCase(templeListThunk.rejected, (state, action) => {
            state.listApi = {
                status: apiStatus.FAILED,
                error: action.payload
            }
        })
        builder.addCase(templeRetrieveThunk.pending, (state, action) => {
            state.readApi = {
                status: apiStatus.LOADING,
                error: null
            }
        })
        builder.addCase(templeRetrieveThunk.fulfilled, (state, action) => {
            state.readApi = {
                status: apiStatus.SUCCEEDED,
                error: null
            }
            templesAdapter.upsertOne(state, action.payload)
        })
        builder.addCase(templeRetrieveThunk.rejected, (state, action) => {
            state.readApi = {
                status: apiStatus.FAILED,
                error: action.payload
            }
        })
        builder.addCase(templeUpdateThunk.pending, (state, action) => {
            state.updateApi = {
                status: apiStatus.LOADING,
                error: null
            }
        })
        builder.addCase(templeUpdateThunk.fulfilled, (state, action) => {
            state.updateApi = {
                status: apiStatus.SUCCEEDED,
                error: null
            }
            templesAdapter.upsertOne(state, action.payload)
        })
        builder.addCase(templeUpdateThunk.rejected, (state, action) => {
            state.updateApi = {
                status: apiStatus.FAILED,
                error: action.payload
            }
        })
        builder.addCase(templeDeleteThunk.pending, (state, action) => {
            state.deleteApi = {
                status: apiStatus.LOADING,
                error: null
            }
        })
        builder.addCase(templeDeleteThunk.fulfilled, (state, action) => {
            state.deleteApi = {
                status: apiStatus.SUCCEEDED,
                error: null
            }
            templesAdapter.removeOne(state, action.payload)
        })
        builder.addCase(templeDeleteThunk.rejected, (state, action) => {
            state.deleteApi = {
                status: apiStatus.FAILED,
                error: action.error.message
            }
        })
        builder.addCase(uploadImgThunk.pending, (state, action) => {
            const loadingState = {
                status: apiStatus.LOADING,
                error: null
            }
            if (action.meta.arg.imgType === 'cover')
                state.imgUploadApi.cover = loadingState
            else if (action.meta.arg.imgType === 'display')
                state.imgUploadApi.display = loadingState
        })
        builder.addCase(uploadImgThunk.fulfilled, (state, action) => {
            const temple = templesAdapter
                .getSelectors()
                .selectById(state, action.meta.arg.id)
            if (temple)
                templesAdapter.upsertOne(state, { ...temple, ...action.payload })
            const successState = {
                status: apiStatus.SUCCEEDED,
                error: null
            }
            if (action.meta.arg.imgType === 'cover')
                state.imgUploadApi.cover = successState
            else if (action.meta.arg.imgType === 'display')
                state.imgUploadApi.display = successState
        })
        builder.addCase(uploadImgThunk.rejected, (state, action) => {
            const errorState = {
                status: apiStatus.FAILED,
                error: action.payload
            }
            if (action.meta.arg.imgType === 'cover')
                state.imgUploadApi.cover = errorState
            else if (action.meta.arg.imgType === 'display')
                state.imgUploadApi.display = errorState
        })
        builder.addCase(staffTempleListThunk.pending, (state, action) => {
            state.listApi = {
                status: apiStatus.LOADING,
                error: null
            }
        })
        builder.addCase(staffTempleListThunk.fulfilled, (state, action) => {
            const {data} = action.payload
            state.listApi = {
                status: apiStatus.SUCCEEDED,
                error: null
            }
            templesAdapter.upsertMany(state, data)
        })
        builder.addCase(staffTempleListThunk.rejected, (state, action) => {
            state.listApi = {
                status: apiStatus.FAILED,
                error: action.payload
            }
        })
        builder.addCase(staffTempleRetrieveThunk.pending, (state, action) => {
            state.readApi = {
                status: apiStatus.LOADING,
                error: null
            }
        })
        builder.addCase(staffTempleRetrieveThunk.fulfilled, (state, action) => {
            state.readApi = {
                status: apiStatus.SUCCEEDED,
                error: null
            }
            templesAdapter.upsertOne(state, action.payload)
        })
        builder.addCase(staffTempleRetrieveThunk.rejected, (state, action) => {
            state.readApi = {
                status: apiStatus.FAILED,
                error: action.payload
            }
        })
        builder.addCase(staffTempleUpdateThunk.pending, (state, action) => {
            state.updateApi = {
                status: apiStatus.LOADING,
                error: null
            }
        })
        builder.addCase(staffTempleUpdateThunk.fulfilled, (state, action) => {
            state.updateApi = {
                status: apiStatus.SUCCEEDED,
                error: null
            }
            templesAdapter.upsertOne(state, action.payload)
        })
        builder.addCase(staffTempleUpdateThunk.rejected, (state, action) => {
            state.updateApi = {
                status: apiStatus.FAILED,
                error: action.payload
            }
        })
        builder.addCase(templeActivationThunk.pending, (state, action) => {
            state.activationApi = {
                status: apiStatus.LOADING,
                error: null
            }
        })
        builder.addCase(templeActivationThunk.fulfilled, (state, action) => {
            state.activationApi = {
                status: apiStatus.SUCCEEDED,
                error: null
            }
            const temple = templesAdapter.getSelectors()
                .selectById(state, action.meta.arg.id)
            if (temple)
                templesAdapter.upsertOne(state, {...temple, isActive: action.meta.arg.shouldActivate})
        })
        builder.addCase(templeActivationThunk.rejected, (state, action) => {
            state.activationApi = {
                status: apiStatus.FAILED,
                error: action.error.message
            }
        })
    }
})

export default templeSlice.reducer

export const selectCurrentTemple = (state: AppState) => {
    let currentTemple = state.temple.currentTemple
    return currentTemple && currentTemple in state.temple.entities ? (
        state.temple.entities[currentTemple]
    ) : null
}

export const selectCurrentTempleId = (state: AppState) => state.temple.currentTemple

export const selectTemplesState = (state: AppState) => {
    return state.temple
}

export const { resetState, upsertTemple } = templeSlice.actions

export const {
    selectAll: selectAllTemples,
    selectById: selectTempleById,
    selectIds: selectTempleIds,
} = templesAdapter.getSelectors((state: AppState) => state.temple)

export const selectTempleCreateApi = (state: AppState) => state.temple.createApi
export const selectTempleListApi = (state: AppState) => state.temple.listApi
export const selectTempleReadApi = (state: AppState) => state.temple.readApi
export const selectTempleUpdateApi = (state: AppState) => state.temple.updateApi
export const selectImgUploadApi = (state: AppState) => state.temple.imgUploadApi
export const selectTempleDeleteApi = (state: AppState) => state.temple.deleteApi
export const selectTempleActivationApi = (state: AppState) => state.temple.activationApi
export const selectTempleByUrl = (state: AppState, url: string) => 
    selectAllTemples(state).find(temple => temple.url === url)
export const selectTempleByIds = createSelector(
    (state: AppState) => state, 
    (_:any, templeIds: (string|number)[]) => templeIds,
    (state, templeIds) => {
        return templeIds.map(id => selectTempleById(state, id))
    } 
)
export const selectAllInactiveTemples = (state: AppState) => 
    selectAllTemples(state).filter(temple => temple.isActive !== undefined && !temple.isActive)
export const selectAllAdminTemples = (state: AppState) =>
    selectAllTemples(state).filter(temple => temple.requestUserRole)