import React, { useEffect, useMemo, useState } from 'react'
import IntlMessages from '@crema/utility/IntlMessages'
import Box from '@material-ui/core/Box'
import GoBack from 'components/GoBack'
import { RouteConfigComponentProps } from 'react-router-config'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import FitContentPaper from 'components/Paper/FitContentPaper'
import { FormikHelpers } from 'formik'
import { apiStatus } from 'shared/constants/AppEnum'
import { IOffering, IOfferingFormType } from 'types/store/temple'
import { offeringMetaRemoveThunk, offeringRetrieveThunk, offeringUpdateThunk, selectOfferingById, selectOfferingStatus } from 'store/reducers/temple/offeringSlice'
import { formToOffering, offeringToForm } from 'utils/temple/offering'
import FormOffering from './FormOffering'
import { getEditedPayload } from 'utils/api'
import { feeSplitConfigCreateThunk, feeSplitConfigDeleteThunk, feeSplitConfigListThunk, selectfeeSplitConfigByContentObject } from 'store/reducers/report_app/feeSplitConfigSlice'
import { IOfferingResponse } from 'types/api/temple'
import { FeeSplitConfigResponseType } from 'api/report_app/report_app.response'
import { areEqualUsingJSON } from 'utils/fns'
import { selectCurrentTemple } from 'store/reducers/temple/templeSlice'
import { selectFeeSplitNameByContentObject } from 'store/reducers/report_app/feeSplitNameSlice'
import { FeeSplitConfigEntryType } from 'api/report_app/report_app.base'

interface RouteParams {
    id: string | undefined
}


const generateInitialValues = (
    offering?: IOfferingResponse,
    feeSplitConfig?: FeeSplitConfigResponseType,
) => offering ? offeringToForm(offering, feeSplitConfig) : undefined


const EditOffering: React.FC<RouteConfigComponentProps<RouteParams>> = (props) => {
    const { id } = props.match.params
    const api = useAppSelector(selectOfferingStatus)
    const offering = useAppSelector(state => selectOfferingById(state, id ? id : ''))
    const [offeringEditState, setOfferingEditState] = useState<IOffering | null>()
    const [alerts, setAlerts] = useState<TqAlert[]>([])
    const feeSplitConfig = useAppSelector(state => selectfeeSplitConfigByContentObject(state, offering?.url ?? ''))
    const dispatch = useAppDispatch()
    const temple = useAppSelector(selectCurrentTemple)
    const feeSplitNames = useAppSelector(state => selectFeeSplitNameByContentObject(state, temple?.url ?? ''))
    const offeringFormState = useMemo(() => generateInitialValues(offering, feeSplitConfig), [offering, feeSplitConfig])

    useEffect(() => {
        if (id)
            dispatch(offeringRetrieveThunk({ id: id }))
    }, [dispatch, id])

    useEffect(() => {
        if (offeringFormState) {
            setOfferingEditState(formToOffering(offeringFormState))
        }
    }, [offeringFormState])

    useEffect(() => {
        if (offering) {
            dispatch(feeSplitConfigListThunk({ contentObject: offering.url }))
        }
    }, [dispatch, offering])

    const getPartialOffering = (a: IOffering, b: IOffering): Partial<IOffering> =>
        getEditedPayload<IOffering>(a, b, ['offeringTimes'])

    const handleSubmit = async (data: IOfferingFormType, actions: FormikHelpers<IOfferingFormType>) => {
        setAlerts([])
        if (offeringEditState && offering?.url) {
            const promises: (() => Promise<any>)[] = []

            let editedOffering = formToOffering(data)
            let deleteLoad = getPartialOffering(editedOffering, offeringEditState)
            let updateLoad = getPartialOffering(offeringEditState, editedOffering)
            // let hasToUpdate = Object.keys(updateLoad).length > 0

            promises.push(async () => {
                if (deleteLoad?.offeringMeta) {
                    return dispatch(offeringMetaRemoveThunk({
                        url: offering.url,
                        data: deleteLoad.offeringMeta
                    })).unwrap()
                        // .catch(err => {
                        //     setAlerts(a => [
                        //         ...a,
                        //         { message: 'Error updating offering', severity: 'error' }
                        //     ])
                        // })
                }
            })

            promises.push(async () => {
                if( offering.url && !areEqualUsingJSON(data.splitEntries, offeringFormState?.splitEntries)){
                    if (data.splitEntries == undefined || data.splitEntries.length == 0){
                        if(feeSplitConfig)
                            return dispatch(feeSplitConfigDeleteThunk({id: feeSplitConfig.id, url: feeSplitConfig.url})).unwrap()
                    } else {
                        const entries : FeeSplitConfigEntryType[] = []
                        for (const item of data.splitEntries){
                            if(item.percent != ""){
                                entries.push({name: item.name, percent: item.percent})
                            }
                        }
                        return dispatch(feeSplitConfigCreateThunk({
                            contentObject: offering.url, 
                            entries: entries
                        })).unwrap()
                    }
                }
            })

            promises.push(async () => {
                if (Object.keys(updateLoad).length > 0 && api.status !== apiStatus.FAILED) {
                    return dispatch(offeringUpdateThunk({
                        url: offering.url,
                        data: updateLoad
                    })).unwrap()
                        // .then(() => {
                        //     setAlerts(a => [
                        //         ...a,
                        //         { message: 'Offering updated', severity: 'success' }
                        //     ])
                        // })
                        // .catch(err => { })
                        // .finally(() => actions.setSubmitting(false))
                }
            })

            await Promise.all(promises.map(p => p()))   
                .then(() => {
                    setAlerts(a => [
                        ...a,
                        { message: 'Offering updated', severity: 'success' }
                    ])
                })            
                .catch(err => {
                    setAlerts(a => [
                        ...a,
                        { message: 'Error updating offering', severity: 'error' }
                    ])
                })
                .finally(() => actions.setSubmitting(false))



        }


        //             Object.entries(getFieldErrors(data, err)).forEach(([key, value]) => 
        //                 actions.setFieldError(key, value))

    }

    return (
        <GoBack {...props}>
            <FitContentPaper>
                <Box component="h4" mb={4}><IntlMessages id="offering.edit.title" /></Box>
                <FormOffering
                    feeSplitNames={feeSplitNames}
                    temple={temple}
                    buttonLabelId="common.update"
                    api={api}
                    initialValues={offeringFormState}
                    onSubmitCb={handleSubmit}
                    alerts={alerts}
                    offering={offering}
                />
            </FitContentPaper>
        </GoBack>
    )
}

export default EditOffering