import {v4 as uuidv4} from 'uuid'
import { tokenKey, deviceIdKey, userRoleKey } from 'shared/constants/AppConst'
import { getLocalStorageItem, setLocalStorageItem } from 'utils/fns'
import { UserRoleType } from 'store/reducers/auth/authSlice'

export const setAuthToken = (token: string | null) => setLocalStorageItem(tokenKey, token)

export const getAuthToken = () => getLocalStorageItem(tokenKey)

export const getDeviceId = () => {
    let deviceId = getLocalStorageItem(deviceIdKey)
    if (!deviceId){
        deviceId = uuidv4()
        setLocalStorageItem(deviceIdKey, deviceId)
    }
    return deviceId
}

export const getLocalUserRole = () => getLocalStorageItem(userRoleKey)
export const setLocalUserRole = (role: UserRoleType) => setLocalStorageItem(userRoleKey, role)