import { CancelToken } from "axios"
import { BankAccountType, ITempleResponse, ParamsOrUrlType } from "types/api/temple"
import { apiInstance } from "../client"
import { StaffBankAccountParamsType, StaffTempleAdminCreateRequest, StaffTempleAdminParams, StaffTempleAdminResponse, StaffTempleParamsType } from "./types"


const basePath = 'temple/staff'

const getTempleUrl = (id?: string | number) => id ? `${basePath}/temple/${id}` : `${basePath}/temple` 
const getBankAccountUrl = () => `${basePath}/bank-account`
const getTempleAdminUrl = () => `${basePath}/temple-admin`

export const templeAPI = {
    search: async(data: ParamsOrUrlType<{query: string}>, cancelToken: CancelToken) => {
        return 'url' in data ?
            await apiInstance.get<ITempleResponse[]>(data.url, {cancelToken: cancelToken}) :
            await apiInstance.get<ITempleResponse[]>(`${getTempleUrl()}/`, {params: {name: data.query}, cancelToken: cancelToken})
    },
    list: async(data: StaffTempleParamsType | UrlType) =>
        'url' in data ? 
            await apiInstance.get<ITempleResponse[]>(data.url) :
            await apiInstance.get<ITempleResponse[]>(`${getTempleUrl()}/`, {params: data}),
    get: async(url: string) =>
        await apiInstance.get<ITempleResponse>(url),
    activate: async(url: string) => await apiInstance.post(`${url}approve/`),
    update: async(url:string, data: Partial<ITempleResponse>) => await apiInstance.patch(url, data),
    deactive: async(url: string) => await apiInstance.post(`${url}disapprove/`),
}

export const bankAccountApi = {
    list: async(data: StaffBankAccountParamsType | UrlType) =>
        'url' in data ? 
            await apiInstance.get<BankAccountType[]>(data.url) :
            await apiInstance.get<BankAccountType[]>(`${getBankAccountUrl()}/`, {params: data}),
    update: async(url: string, data: Partial<BankAccountType>) =>
        await apiInstance.patch<BankAccountType>(url, data),
    get: async(url: string) => await apiInstance.get<BankAccountType>(url),
    activate: async(url: string) =>
        await apiInstance.post(`${url}approve/`),
    deactivate: async(url: string) =>
        await apiInstance.post(`${url}disapprove/`)
}

export const adminTempleAdminApi = {
    list: async(params: StaffTempleAdminParams) => apiInstance.get<StaffTempleAdminResponse[]>(`${getTempleAdminUrl()}/`, {params}),
    get: async(url: string) => apiInstance.get<StaffTempleAdminResponse>(url),
    create: async(data: StaffTempleAdminCreateRequest) => apiInstance.post<StaffTempleAdminResponse>(`${getTempleAdminUrl()}/`, data),
    update: async(url: string, data: Partial<StaffTempleAdminCreateRequest>) => apiInstance.patch<StaffTempleAdminResponse>(url, data),
    delete: async(url: string) => apiInstance.delete(url)
}