import React from 'react';
import TextField  from '@material-ui/core/TextField';
import { useField } from 'formik'

const FormikTextField = (props: any) => {
    const [field, meta] = useField(props);
    const errorText = meta.error && meta.touched ? meta.error : '';
    return (
        <TextField
            // classes={{root: classes.root}}
            // margin="normal"
            {...field}
            {...{margin: 'normal', ...props}}
            helperText={errorText}
            error={!!errorText}
        />
    );
};

export default React.memo(FormikTextField)