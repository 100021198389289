import { Box, Button, Container, Dialog, Divider, Grid, IconButton, InputAdornment, List, ListItem, ListItemText, MenuItem, Popper, TextField, Typography, makeStyles } from '@material-ui/core'
import HeadingCard from 'components/Card/HeadingCard'
import { IRow, MemoizedBasicTable } from 'components/Table/BasicTable'
import React, { useEffect, useMemo, useState } from 'react'
import { ITempleResponse } from 'types/api/temple'
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import { useIntl } from 'react-intl'
import axios from 'axios'
import userAPI from 'api/user'
import { Form, Formik, FormikProvider, useFormik } from 'formik'
import * as yup from 'yup'
import { StaffTempleAdminResponse, TempleAdminRoleEnum } from 'api/admin/types'
import FormikTextField from 'components/Fields/FormikTextField'
import { extractError, getEditedPayload, isFieldError } from 'utils/api'
import { apiStatus } from 'shared/constants/AppEnum'
import { Alert } from '@material-ui/lab'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { selectStaffTempleAdminStatus, selectStaffTempleAdminsByTempleId, staffTempleAdminCreateThunk, staffTempleAdminDeleteThunk, staffTempleAdminListThunk, staffTempleAdminUpdateThunk } from 'store/reducers/temple/staffTempleAdminSlice'
import { CremaTheme } from 'types/crema/AppContextPropsType'
import clsx from 'clsx';

type TempleAdminTableProps = {
    temple: ITempleResponse
}

const getRows = (admins: StaffTempleAdminResponse[]): IRow[] => admins.map(e => ({
    id: e.user.id, values: [
        <Typography variant='body2'>{e.user.id}</Typography>,
        <Typography variant='body2'>{e.user.fullName}</Typography>,
        <Typography variant='body2'>{e.user.email ?? ''}</Typography>,
        <Typography variant='body2'>{e.user.phone ?? ''}</Typography>,
        <Typography variant='body2'>{e.role}</Typography>
    ]
}))

const TempleAdminTable: React.FC<TempleAdminTableProps> = ({ temple }) => {
    const dispatch = useAppDispatch()
    const admins = useAppSelector(state => selectStaffTempleAdminsByTempleId(state, temple.id))
    const rows = useMemo(() => admins !== undefined ? getRows(admins) : [], [admins])
    const [dialogOpen, setDialogOpen] = useState(false)
    const [selectedAdmin, setSelectedAdmin] = useState<StaffTempleAdminResponse | undefined>()

    useEffect(() => {
        dispatch(staffTempleAdminListThunk({ temple: temple.id }))
    }, [temple.id])


    return (
        <>
            <HeadingCard title="Temple Admins" action={(
                <IconButton size='small' onClick={() => {
                    setSelectedAdmin(undefined)
                    setDialogOpen(true)
                }}>
                    <AddIcon />
                </IconButton>
            )}>
                <MemoizedBasicTable
                    rows={rows}
                    headRow={[
                        'common.id',
                        'common.name',
                        'common.email',
                        'common.phone',
                        'common.role'
                    ]}
                    editHandler={val => {
                        if (admins) {
                            const index = admins?.findIndex(e => e.user.id == val)
                            if (index != -1) {
                                setSelectedAdmin(admins[index])
                                setDialogOpen(true)
                            }
                        }
                    }}
                    deleteHandler={val => {
                        if (admins) {
                            const index = admins?.findIndex(e => e.user.id == val)
                            if (index != -1) {
                                dispatch(staffTempleAdminDeleteThunk({ id: admins[index].id, url: admins[index].url }))
                            }
                        }
                    }}
                // deletingIds={deletingIds}
                />
            </HeadingCard>
            <TempleAdminDialog
                open={dialogOpen}
                temple={temple}
                admin={selectedAdmin}
                handleClose={() => setDialogOpen(false)}
            />
        </>
    )
}

export default TempleAdminTable

type TempleAdminDialogProps = {
    open: boolean,
    handleClose: () => void,
    temple: ITempleResponse
    admin?: StaffTempleAdminResponse
}

const useStyles = makeStyles((theme: CremaTheme) => ({
    userBox: {
        padding: 8,
        borderRadius: 4
    },
    errorUser: {
        border: `1px solid ${theme.palette.error.main}`
    },
    successUser: {
        border: `1px solid ${theme.palette.primary.main}`
    }
}));

const TempleAdminDialog: React.FC<TempleAdminDialogProps> = ({ open, handleClose, admin, temple }) => {
    const { messages } = useIntl()
    const classes = useStyles()
    const [emailQuery, setEmailQuery] = useState('')
    const [phoneQuery, setPhoneQuery] = useState('')
    const [cancelToken, setCancelToken] = useState(axios.CancelToken.source())
    const [users, setUsers] = useState<PublicUserResponseType[]>([])
    const [anchorEl, setAnchorEl] = React.useState<any>(null);
    const api = useAppSelector(selectStaffTempleAdminStatus)
    const dispatch = useAppDispatch()

    const initialValues = useMemo(() => ({
        user: admin?.user ?? undefined,
        temple: temple,
        role: admin?.role ?? TempleAdminRoleEnum.ADMIN
    }), [admin, temple])


    const validationSchema = yup.object({
        user: yup.object({ id: yup.number().required() }),
        temple: yup.object({ id: yup.number().required() }),
        role: yup.string().oneOf(Object.values(TempleAdminRoleEnum)).required()
    })

    const formMethods = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        validateOnChange: true,
        enableReinitialize: true,
        onSubmit: async (values, helpers) => {
            console.log('submitted')
            if (admin == undefined) {
                await dispatch(staffTempleAdminCreateThunk({
                    temple: temple.id,
                    user: values.user!.id,
                    role: values.role
                }))
                handleClose()
            } else {
                await dispatch(staffTempleAdminUpdateThunk({ url: admin.url, data: { role: values.role } }))
                handleClose()
            }

        },
    });

    const { values, setValues, setFieldValue, errors, validateForm, isSubmitting, isValid, dirty, submitForm } = formMethods


    const handleSearch = (params: { email: string } | { phone: string }) => {
        if (cancelToken) {
            cancelToken.cancel('search query modified')
        }
        const tokenSource = axios.CancelToken.source()
        setCancelToken(tokenSource)
        // setLoading(true)
        userAPI.listUser(params, tokenSource.token)
            .then((res) => setUsers(res.data))
            .catch(err => setUsers([]))
        // setLoading(false)
    }

    const handleEmailQueryChange = (query: string) => {
        setEmailQuery(query)
        if (query) {
            handleSearch({ email: query })
        } else {
            setUsers([])
        }
    }

    const handlePhoneQueryChange = (query: string) => {
        setPhoneQuery(query)
        if (query) {
            handleSearch({ phone: query })
        } else {
            setUsers([])

        }
    }
    return (

        <Dialog open={open}>
            <FormikProvider value={formMethods}>
                <Container style={{ minHeight: 500, paddingTop: 10, paddingBottom: 10 }}>

                    <Grid container spacing={4} direction='column'>
                        <Grid container item xs={12} justify={'flex-end'}>
                            <Grid item>
                                <IconButton edge="start" onClick={handleClose} aria-label="close">
                                    <CloseIcon color="primary" />
                                </IconButton>
                            </Grid>
                        </Grid>

                        <Grid container item xs={12} spacing={4}
                            // justify='center' 
                            // alignItems='center' 
                            direction='column'>
                            {admin == undefined && (
                                <>
                                    <Grid item xs={12}>
                                        <TextField
                                            value={emailQuery}
                                            size='small'
                                            variant='outlined'
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <EmailIcon />
                                                    </InputAdornment>
                                                )
                                            }}
                                            label={messages['common.email']}
                                            // placeholder={messages['sidebar.search.temple'].toString()}
                                            onChange={e => (handleEmailQueryChange(e.target.value), setAnchorEl(e.currentTarget))}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            value={phoneQuery}
                                            size='small'
                                            variant='outlined'
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <PhoneIcon />
                                                    </InputAdornment>
                                                )
                                            }}
                                            label={messages['common.phone']}
                                            // placeholder={messages['sidebar.search.temple'].toString()}
                                            onChange={e => (handlePhoneQueryChange(e.target.value), setAnchorEl(e.currentTarget))}
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                </>
                            )}
                            <Grid item xs={12}>

                                <Box className={clsx(classes.userBox, values.user ? classes.successUser : classes.errorUser)}>
                                    {values.user ? (
                                        <>
                                            <Typography variant='body1'>{values.user.fullName}</Typography>
                                            <Typography variant='body2'>{`${values.user.email ?? ''}${values.user.email && values.user.phone ? ', ' : ''}${values.user.phone ?? ''}`}</Typography>
                                        </>
                                    ) : (
                                        <Typography>{errors.user ?? 'User is required'}</Typography>
                                    )}
                                </Box>

                            </Grid>

                            <Grid item xs={12}>
                                <FormikTextField
                                    select
                                    name='role'
                                    variant='outlined'
                                    required
                                    fullWidth
                                    label={messages['common.role']}
                                    size="small"
                                >
                                    {Object.values(TempleAdminRoleEnum).map((val, index) => (
                                        <MenuItem key={index} value={val}>
                                            {val}
                                        </MenuItem>
                                    ))}
                                </FormikTextField>
                            </Grid>

                            <Grid item xs={12}>
                                <Button
                                    disabled={isSubmitting || !isValid || !dirty}
                                    variant="contained"
                                    color="primary"
                                    onClick={() => submitForm()}
                                >
                                    {messages['common.save']}
                                </Button>
                            </Grid>
                            {api.status === apiStatus.FAILED && !isFieldError(api.error) && (
                                <Grid item xs={12}>
                                    <Alert severity="error">{extractError(api.error)}</Alert>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>

                </Container>
                <Popper id="popper-user-search" open={emailQuery != "" || phoneQuery != ""} anchorEl={anchorEl} style={{ zIndex: 9999 }} placement='bottom'>
                    <Box style={{ maxHeight: 300, overflow: 'auto' }}>
                        <List style={{ backgroundColor: 'white' }}>
                            {users.map((user, index) => (
                                <React.Fragment key={user.id}>
                                    <ListItem button onClick={() => (setFieldValue('user', user), setEmailQuery(""), setPhoneQuery(""))}>
                                        <ListItemText primary={user.fullName} secondary={`${user.email ?? ''}${user.email && user.phone ? ', ' : ''}${user.phone ?? ''}`} />
                                    </ListItem>
                                    {index + 1 < users.length && <Divider />}
                                </React.Fragment>
                            ))}
                        </List>
                    </Box>
                </Popper>
            </FormikProvider>
        </Dialog>
    )
}
