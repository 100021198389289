import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import userAPI from 'api/user'
import { apiStatus } from 'shared/constants/AppEnum'
// circular import
import { AppState } from 'store/reduxStore'
import { tokenKey } from 'shared/constants/AppConst'
import { getAuthToken, getLocalUserRole, setLocalUserRole } from 'utils/auth'
import { getDeviceId } from 'utils/auth'
import { resetState as resetDeityState } from 'store/reducers/temple/deitySlice'
import { resetState as resetOfferingState } from 'store/reducers/temple/offeringSlice'
import { resetState as resetOfferingCategoryState } from 'store/reducers/temple/offeringCategorySlice'
import { resetState as resetOrderState } from 'store/reducers/temple/orderSlice'
import { resetState as resetReceiptState } from 'store/reducers/temple/receiptSlice'
import { resetState as resetTempleState } from 'store/reducers/temple/templeSlice'
import { resetState as resetSettingsState } from 'store/reducers/settings/settingsSlice'
import { IApiState } from 'store/types'

export type UserRoleType = 'user' | 'staff'

export interface IAuth extends IApiState {
    token: string | null
    user: UserResponseType | null
}

const getInitialState = () : IAuth => {
    const token = getAuthToken()
    return { 
        status: apiStatus.IDLE,
        token: token,
        user: null,
    } 
}

export const loginThunk = createAsyncThunk('auth/login', async (payload: ILoginRequest, {rejectWithValue}) => {
    localStorage.removeItem(tokenKey)
    try{
        const response = await userAPI.login({...payload, deviceId: getDeviceId()})
        return response.data
    } catch (err:any) {
        return rejectWithValue(err.response.data)
    }
})

export const logoutThunk = createAsyncThunk('auth/logout', async(payload: null|undefined, {rejectWithValue, dispatch}) => {
    try{
        await userAPI.logout()
        dispatch(resetDeityState())
        dispatch(resetOfferingState())
        dispatch(resetOfferingCategoryState())
        dispatch(resetOrderState())
        dispatch(resetReceiptState())
        dispatch(resetTempleState())
        dispatch(resetSettingsState())
        return true
    } catch (err:any){
        return rejectWithValue(err.response.data)
    }
})

export const userThunk = createAsyncThunk(
    'auth/user', 
    async(
        payload: undefined | null | Partial<UserResponseType>, {rejectWithValue}
    ) => {
        try{
            const response = payload ? await userAPI.updateUser(payload) : await userAPI.getUser()
            return response.data
        } catch (err:any) {
            return rejectWithValue(err.response.data)
        }
    }
)


const authSlice = createSlice({
    name: 'auth',
    initialState: getInitialState(),
    reducers: {
        setToken(state, action){
            const { token } = action.payload
            state.token = token
        },
        setUserRole(state, action : PayloadAction<UserRoleType>){
            if(state.user) {
                state.user.role = action.payload
                setLocalUserRole(action.payload)
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(loginThunk.pending, (state, action) => {
            state.status = apiStatus.LOADING
            state.error = ''
        })
        builder.addCase(loginThunk.fulfilled, (state, action) => {
            const { token, user} = action.payload
            state.status = apiStatus.SUCCEEDED
            state.token = token
            let userrole : UserRoleType = user.isStaff ? 'staff' : 'user'
            setLocalUserRole(userrole)
            state.user = {...user, role:userrole}
        })
        builder.addCase(loginThunk.rejected, (state, action) => {
            state.status = apiStatus.FAILED
            state.error = action.payload 
        })
        builder.addCase(logoutThunk.pending, (state, action) => {
            state.status = apiStatus.LOADING
        })
        builder.addCase(logoutThunk.fulfilled, (state, action) => {
            state.status = apiStatus.SUCCEEDED
            state.token = null
            state.user = null
        })
        builder.addCase(logoutThunk.rejected, (state, action) => {
            state.status = apiStatus.FAILED
            state.token = null
            state.user = null
        })
        builder.addCase(userThunk.pending, (state, action) => {
            state.status = apiStatus.LOADING
        })
        builder.addCase(userThunk.fulfilled, (state, action) => {
            state.status = apiStatus.SUCCEEDED
            state.user = {...action.payload, role: getLocalUserRole()}
        })
        builder.addCase(userThunk.rejected, (state, action) => {
            state.status = apiStatus.FAILED
            state.error = action.payload
        })
    }
})

export default authSlice.reducer

export const { setToken, setUserRole } = authSlice.actions
export const selectUser = (state: AppState) => state.auth.user
export const selectAuth = (state: AppState) => state.auth
export const selectIsAuthenticated = (state: AppState) => state.auth.token ? true : false
export type AuthState = ReturnType<typeof selectAuth>