import IntlMessages from "@crema/utility/IntlMessages"
import { Box } from "@material-ui/core"
import { unwrapResult } from "@reduxjs/toolkit"
import GoBack from "components/GoBack"
import FitContentPaper from "components/Paper/FitContentPaper"
import { FormikHelpers } from "formik"
import { useEffect, useState } from "react"
import { RouteConfigComponentProps } from "react-router-config"
import { apiStatus } from "shared/constants/AppEnum"
import { useAppDispatch, useAppSelector } from "store/hooks"
import { donationMetaRemoveThunk, donationRetrieveThunk, donationUpdateThunk, selectDonationById, selectDonationStatus } from "store/reducers/temple/donationSlice"
import { IDonation, IDonationFormType } from "types/store/temple"
import { getEditedPayload } from "utils/api"
import { donationToForm, formToDonation } from "utils/temple/donation"
import FormDonation from "./FormDonation"

interface RouteParams {
    id: string | undefined
}

const EditDonation: React.FC<RouteConfigComponentProps<RouteParams>> = (props) => {
    const { id } = props.match.params
    const api = useAppSelector(selectDonationStatus)
    const donation = useAppSelector(state => selectDonationById(state, id ? id : ''))
    const [donationEditState, setDonationEditState] = useState<IDonation|undefined>()
    const [donationFormState, setDonationFormState] = useState<IDonationFormType|undefined>()
    const [alerts, setAlerts] = useState<TqAlert[]>([])

    const dispatch = useAppDispatch()
    useEffect(() => {
        if(id)
            dispatch(donationRetrieveThunk({id: id}))
    }, [dispatch, id])

    useEffect(() => {
        if (donation?.donationMeta) {
            setDonationFormState(donationToForm(donation))
        }
    }, [donation])

    useEffect(() => {
        if (donationFormState) {
            setDonationEditState(formToDonation(donationFormState))
        }
    }, [donationFormState])

    const getPartialDonation = (a: IDonation, b: IDonation): Partial<IDonation> =>
        getEditedPayload<IDonation>(a, b)

    const handleSubmit = async (data: IDonationFormType, actions: FormikHelpers<IDonationFormType>) => {
        setAlerts([])
        if (donationEditState && donation?.url) {
            let editedDonation = formToDonation(data)
            let deleteLoad = getPartialDonation(editedDonation, donationEditState)
            let updateLoad = getPartialDonation(donationEditState, editedDonation)
            let hasToUpdate = Object.keys(updateLoad).length > 0
            if (deleteLoad?.donationMeta) {
                await dispatch(donationMetaRemoveThunk({
                    url: donation.url,
                    data: deleteLoad.donationMeta
                })).then(unwrapResult)
                .catch(err => {
                    setAlerts(a => [
                        ...a, 
                        {message:'Error updating donation', severity: 'error'}
                    ])
                })
                .finally(() => {
                    if (!hasToUpdate){
                        setAlerts(a => [
                            ...a, 
                            {message:'Donation updated', severity: 'success'}
                        ])
                        actions.setSubmitting(false)
                    }
                })
            }
            
            if ( hasToUpdate && api.status !== apiStatus.FAILED) {
                await dispatch(donationUpdateThunk({
                    url: donation.url,
                    data: updateLoad
                })).then(unwrapResult)
                .then(() => {
                    setAlerts(a => [
                        ...a, 
                        {message:'Donation updated', severity: 'success'}
                    ])
                })
                .catch(err => {})
                .finally(() => actions.setSubmitting(false))
            }
            
        }
        

        //             Object.entries(getFieldErrors(data, err)).forEach(([key, value]) => 
        //                 actions.setFieldError(key, value))

    }
    return (
        <GoBack {...props}>
            <FitContentPaper>
                <Box component="h4" mb={4}><IntlMessages id="donation.edit.title" /></Box>
                <FormDonation
                    buttonLabelId="common.update"
                    api={api}
                    initialValues={donationFormState}
                    onSubmitCb={handleSubmit}
                    alerts={alerts}
                />
            </FitContentPaper>
        </GoBack>
    )
}

export default EditDonation