import React from 'react';
import Box from '@material-ui/core/Box';
import {RouteConfigComponentProps, renderRoutes} from 'react-router-config'
import { Redirect } from 'react-router-dom'
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { selectCurrentTemple, selectCurrentTempleId, selectTempleListApi, templeListThunk } from 'store/reducers/temple/templeSlice';
import { Typography } from '@material-ui/core';
import IntlMessages from '@crema/utility/IntlMessages';
import { apiStatus } from 'shared/constants/AppEnum';
import Loader from '@crema/core/Loader';
import { feeSplitNameListThunk } from 'store/reducers/report_app/feeSplitNameSlice';

const Dashboard : React.FC<RouteConfigComponentProps>= ({ route, location }) => {
    const {pathname} = location;
    const currentTemple = useAppSelector(selectCurrentTemple)
    const listApi = useAppSelector(selectTempleListApi)
    const dispatch = useAppDispatch()
    React.useEffect(()=> {
        dispatch(templeListThunk())
    }, [dispatch])

    React.useEffect(()=> {
        if (currentTemple)
            dispatch(feeSplitNameListThunk({contentObject: currentTemple.url}))
    }, [dispatch, currentTemple])
    
    return (
        listApi.status === apiStatus.LOADING ? <Loader /> : (
            currentTemple ? (
                <Box marginBottom={20}>
                    {/^\/dashboard\/?$/gm.test(pathname) ? 
                        <Redirect to="/dashboard/bookings" /> : 
                        renderRoutes(route?.routes)
                    }
                </Box>
            ): <Typography><IntlMessages id="temple.404" /></Typography>
        )   
    )
};

export default Dashboard;
