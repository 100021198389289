import React, { useState } from 'react'
import IntlMessages from '@crema/utility/IntlMessages'
import Box from '@material-ui/core/Box'
import GoBack from 'components/GoBack'
import { RouteConfigComponentProps } from 'react-router-config'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import FitContentPaper from 'components/Paper/FitContentPaper'
import {IDonationCategoryResponse } from 'types/api/temple'
import { FormikHelpers } from 'formik'
import { unwrapResult } from '@reduxjs/toolkit'
import { getFieldErrors } from 'utils/api'
import { IDonationCategory } from 'types/store/temple'
import { donationCategoryCreateThunk, selectDonationCategoryStatus } from 'store/reducers/temple/donationCategorySlice'
import FormDonationCategory from './FormDonationCategory'


const AddDonationCategory: React.FC<RouteConfigComponentProps> = (props) => {
    const api = useAppSelector(selectDonationCategoryStatus)
    const dispatch = useAppDispatch()
    const [addedItem, setAddedItem] = useState<IDonationCategoryResponse | null>(null)
    const handleSubmit = (data: IDonationCategory, actions: FormikHelpers<IDonationCategory>) => {
        setAddedItem(null)
        dispatch(donationCategoryCreateThunk(data))
            .then(unwrapResult)
            .then(response => {
                if(response && !Array.isArray(response))
                    setAddedItem(response)
                actions.resetForm()
            })
            .catch(err => {
                Object.entries(getFieldErrors(data, err)).forEach(([key, value]) => 
                    actions.setFieldError(key, value))
            })
            .finally(() => {
                actions.setSubmitting(false)
            })
    }
    const handleSuccess = () => addedItem ? `Donation Category ${addedItem.name} Successfully added` : ''

    return (
        <GoBack {...props}>
            <FitContentPaper>
                <Box component="h5" mb={4}><IntlMessages id="donation.category.add.title" /></Box>
                <FormDonationCategory
                    buttonLabelId="common.create"
                    api={api}
                    initialValues={{ name: '' }}
                    onSubmitCb={handleSubmit}
                    isSuccess={Boolean(addedItem)}
                    successCb={handleSuccess}
                />
            </FitContentPaper>
        </GoBack>
    )
}

export default AddDonationCategory