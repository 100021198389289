import React from 'react'
import OfferingTransferList from './OfferingTransferList'
import * as yup from 'yup'
import { Form, Formik, FormikHelpers } from 'formik'
import { deityFormInitValues } from 'utils/temple/deity'
import { IOfferingResponse } from 'types/api/temple'
import { IDeityType } from 'types/store/temple'
import FormikTextField from 'components/Fields/FormikTextField'
import { useIntl } from 'react-intl'
import IntlMessages from '@crema/utility/IntlMessages'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Alert from '@material-ui/lab/Alert'
import { apiStatus } from 'shared/constants/AppEnum'
import { extractError, isFieldError } from 'utils/api'
import { IApiState } from 'store/types'
import Grid from '@material-ui/core/Grid'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'

type FormSubmitHandleType =
    ((values: IDeityType, formikHelpers: FormikHelpers<IDeityType>) => void | Promise<any>) & Function

interface FormDeityProps {
    initialValues?: IDeityType
    buttonLabelId: string
    offerings: IOfferingResponse[]
    handleAddOfferings: ((offerings: IOfferingResponse[]) => void | Promise<any>) & Function
    handleRemoveOfferings: ((offerings: IOfferingResponse[]) => void | Promise<any>) & Function
    onSubmitCb: FormSubmitHandleType
    api: IApiState
    alerts: TqAlert[]
}

const FormDeity: React.FC<FormDeityProps> = props => {
    const {
        initialValues,
        offerings,
        handleAddOfferings,
        handleRemoveOfferings,
        buttonLabelId,
        alerts,
        onSubmitCb,
        api,
    } = props
    const { messages } = useIntl()
    const validationSchema = yup.object({
        name: yup
            .string()
            .required('Name is required'),
        isMain: yup
            .boolean()
            .required('Required'),
    })
    return (
        <Formik
            enableReinitialize
            validateOnChange={true}
            initialValues={initialValues ?? deityFormInitValues}
            validationSchema={validationSchema}
            onSubmit={onSubmitCb}
        >
            {({ isSubmitting, dirty, values, isValid, setFieldValue, errors }) => (
                <Form noValidate autoComplete='off'>
                    <Grid container spacing={6} direction="column">
                        <Grid item>
                            <FormikTextField
                                placeholder={messages['common.name']}
                                name='name'
                                label={<IntlMessages id='common.name' />}
                                variant='outlined'
                                required
                            />
                        </Grid>
                        <Grid item>
                            <FormControl component="fieldset">
                                <FormLabel component="legend"><IntlMessages id="deity.main.title" /></FormLabel>
                                <RadioGroup 
                                    row
                                    aria-label="main prathistta" 
                                    name="isMain" 
                                    value={values.isMain ? "yes": "no"} 
                                    onChange={event => setFieldValue(event.target.name, event.target.value === 'yes'? true : false)}
                                >
                                    <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                    <FormControlLabel value="no" control={<Radio />} label="No" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <Box mb={4} component="h5">
                                <IntlMessages id="deity.offerings.assign.title" />
                            </Box>
                            <OfferingTransferList
                                chosenOfferings={offerings}
                                handleAddOfferings={handleAddOfferings}
                                handleRemoveOfferings={handleRemoveOfferings}
                            />
                        </Grid>
                        <Grid item>
                            <Button disabled={!dirty || isSubmitting || !isValid} variant="contained" color="primary" type="submit">
                                <IntlMessages id={buttonLabelId} />
                            </Button>
                        </Grid>
                        <Grid item container spacing={4} direction="column" alignItems="flex-start">
                            {alerts.map((alert, index) => (
                                <Grid item  key={index}>
                                    <Alert severity={alert.severity}>{alert.message}</Alert>
                                </Grid>
                            ))}
                            {api.status === apiStatus.FAILED && !isFieldError(api.error) && (
                                <Grid item>
                                    <Alert severity="error">{extractError(api.error)}</Alert>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>

    )
}

export default React.memo(FormDeity)