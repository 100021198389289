import React, { FC, Fragment, useCallback, useState } from 'react';

function isGoogleMapScriptLoaded(id: string): boolean {
    const scripts: HTMLCollectionOf<HTMLScriptElement> = document.head.getElementsByTagName('script');
    for (let i: number = 0; i < scripts.length; i++) {
        if (scripts[i].getAttribute('id') === id) {
            return true;
        }
    }

    return false;
}

function loadScript(src: string, id: string) {
    if (isGoogleMapScriptLoaded(id)) {
        // Make sure the script is loaded
        return new Promise((resolve) => setTimeout(resolve, 500));
    }

    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('id', id);
    script.src = src;
    (document.querySelector('head') as any).appendChild(script);

    return new Promise<void>((resolve) => {
        script.onload = () => {
            resolve();
        };
    });
}

type Location = {
    lat: number,
    lng: number
}

type Props = {
    apiKey: string,
    defaultLocation: Location;
    zoom?: number;
    onChangeLocation?(lat: number, lng: number): void;
    onChangeZoom?(zoom: number): void;
    className?: string;
    showSearchBox?: boolean
    draggable?: boolean
    fixed?: boolean
}

function isValidLocation(location: Location) {
    return location && Math.abs(location.lat) <= 90 && Math.abs(location.lng) <= 180;
}

const GOOGLE_SCRIPT_URL = 'https://maps.googleapis.com/maps/api/js?libraries=places&key=';

const MapPicker: FC<Props> = props => {
    const {
        apiKey, 
        defaultLocation, 
        zoom = 7, 
        onChangeLocation, 
        onChangeZoom, 
        className, 
        showSearchBox=true,
        draggable=true,
        fixed=false
    } = props
    const [viewId, setViewId] = useState('')
    const map = React.useRef<any>(null);
    const marker = React.useRef<any>(null);

    const getMapId = useCallback(() => 'google-map-view-' + viewId, [viewId])
    const getSearchId = useCallback(() => 'google-map-search-' + viewId, [viewId])

    const handleChangeLocation = useCallback(() => {
        if (onChangeLocation) {
            const currentLocation = marker.current.getPosition();
            onChangeLocation(currentLocation.lat(), currentLocation.lng());
        }
    }, [onChangeLocation])

    const handleChangeZoom = useCallback(() => {
        onChangeZoom && onChangeZoom(map.current.getZoom());
    }, [onChangeZoom])

    const loadMap = useCallback(() => {
        const Google = (window as any).google;
        const validLocation = isValidLocation(defaultLocation) ? defaultLocation : { lat: 0, lng: 0 };
        map.current = new Google.maps.Map(document.getElementById(getMapId()),
            {
                center: validLocation,
                zoom: zoom,
                mapTypeControlOptions: { mapTypeIds: [] },
                streetViewControl: false
            });

        if (!marker.current) {
            marker.current = new Google.maps.Marker({
                position: validLocation,
                map: map.current,
                draggable: draggable
            });
            if(draggable)
                Google.maps.event.addListener(marker.current, 'dragend', handleChangeLocation);
        } else {
            marker.current.setPosition(validLocation);
        }

        if (!fixed)
            map.current.addListener('click', function (event: any) {
                const clickedLocation = event.latLng;

                marker.current.setPosition(clickedLocation);
                handleChangeLocation();
            });

        map.current.addListener('zoom_changed', handleChangeZoom);
        if (showSearchBox) {

            // Create the search box and link it to the UI element.
            const input = document.getElementById(getSearchId()) as HTMLInputElement;
            const searchBox = new Google.maps.places.SearchBox(input);
            map.current.controls[Google.maps.ControlPosition.TOP_LEFT].push(input);

            // Bias the SearchBox results towards current map's viewport.
            map.current.addListener("bounds_changed", () => {
                searchBox.setBounds(map.current.getBounds())
            });
            searchBox.addListener("places_changed", () => {
                const places = searchBox.getPlaces();

                if (places.length === 0) {
                    return;
                }
                let place = places[0]
                if (!place.geometry || !place.geometry.location) {
                    console.log("Returned place contains no geometry");
                    return;
                }
                if (onChangeLocation)
                    onChangeLocation(place.geometry.location.lat(), place.geometry.location.lng())
                if (marker.current) {
                    map.current.setCenter(place.geometry.location);
                    marker.current.setPosition(place.geometry.location);
                }
            });
        }
    }, [
        defaultLocation,
        getMapId,
        getSearchId,
        handleChangeLocation,
        handleChangeZoom,
        onChangeLocation,
        zoom,
        showSearchBox,
        draggable,
        fixed
    ])

    React.useEffect(() => {
        if (viewId) {
            if (!map.current) {
                loadScript(GOOGLE_SCRIPT_URL + apiKey, 'google-maps-' + apiKey).then(loadMap)
            }
        } else setViewId(Math.random().toString(36).substr(2, 9))
    }, [viewId, setViewId, map, apiKey, loadMap])


    React.useEffect(() => {
        if (map.current) {
            map.current.setZoom(zoom);
        }
    }, [zoom]);

    return (
        <Fragment>
            {showSearchBox && (
                <input
                    id={getSearchId()}
                    type='text'
                    placeholder='Enter search location'
                    style={{
                        boxSizing: `border-box`,
                        border: `1px solid transparent`,
                        width: `240px`,
                        height: `32px`,
                        marginTop: `20px`,
                        padding: `0 12px`,
                        borderRadius: `3px`,
                        boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                        fontSize: `14px`,
                        outline: `none`,
                        marginLeft: '10px',
                        textOverflow: `ellipses`,
                    }}
                />
            )}
            <div id={getMapId()} style={{ width: '100%', height: '100%' }} className={className}></div>
        </Fragment>

    );
};
export default MapPicker;