import { OrderResponseType } from 'types/api/order'
import { DonationReceiptResponseType, ReceiptResponseType } from 'types/api/receipt'
import { BankAccountType, IDeityResponse, IDonationCategoryResponse, IDonationResponse, IOfferingCategoryResponse, IOfferingResponse, IOfferingTimeResponse, ITempleResponse, ParamWithUrlType } from 'types/api/temple'
import { IDeityType, IDonation, IDonationCategory, IDonationMetaType, IOffering, IOfferingCategory, IOfferingMetaType, IOfferingTime, TempleImgType } from 'types/store/temple'
import { getLocalCurrentTemple } from 'utils/temple'
import { apiInstance, deleteWithUrl, uploadInstance } from '../client'
import { DeepPartial } from '@reduxjs/toolkit'
import { objectToFormData } from 'utils/api'
import { ReceiptFilterArgsType } from 'store/reducers/temple/receiptSlice/types'
import { configureDonationReceiptFilterParams, configureOfferingFinancialFilterParams, configureOfferingReceiptFilterParams } from './fns'
import {  IOfferingFinancialResponseType } from 'types/api/offeringFinancial'
import { OfferingFinancialFilterType } from 'store/reducers/temple/offeringFinancialSlice'
import { DonationReceiptFilterArgsType } from 'store/reducers/temple/donationReceiptSlice/types'

const basePath = 'temple/t-admin'

const getTempleBasePath = (templeId?: string|number) =>
    `${basePath}/${templeId ?? getLocalCurrentTemple()}`

const getOCUrl = (templeId: string|number) => `${getTempleBasePath(templeId)}/offering-category`
const getOfferingUrl = (templeId: string|number) =>  `${getTempleBasePath(templeId)}/offering`
const getDeityUrl = (templeId: string|number) => `${getTempleBasePath(templeId)}/deity`
const getOrderUrl = (templeId?: string|number) => `${getTempleBasePath(templeId)}/orders`
const getReceiptUrl = (templeId?: string|number) => `${getTempleBasePath(templeId)}/offering-receipt`
const getDonationReceiptUrl = (templeId?: string|number) => `${getTempleBasePath(templeId)}/donation-receipt`
const getPriestReceiptUrl = (templeId?: string|number) => `${getTempleBasePath(templeId)}/receipt-priest`
const getDCUrl =(templeId:string|number) =>`${getTempleBasePath(templeId)}/donation-category`
const getDonationUrl =(templeId:string|number) => `${getTempleBasePath(templeId)}/donation`
const getMarkAsCompleteUrl =(templeId?: string|number) => `${getTempleBasePath(templeId)}/offering-order`


const templeAPI = {
    ...deleteWithUrl,
    list: async () => await apiInstance.get<ITempleResponse[]>(`${basePath}/`),
    get: async(params?: ParamWithUrlType) => {
        if (params){
            return await apiInstance.get<ITempleResponse>(
                'url' in params ? params.url : 
                `${basePath}/${params.itemId}/`
            )
        } else {
            return await apiInstance.get<ITempleResponse>(`${getTempleBasePath()}/`)
        }
    },
    update: async(url: string, data: DeepPartial<ITempleResponse>) =>
        await apiInstance.patch<ITempleResponse>(url, data),
    uploadImg: async(url: string, type: TempleImgType, file: File) =>{
        const formData = new FormData()
        formData.append(`${type}_img`, file)
        return await uploadInstance.put<Partial<ITempleResponse>>(`${url}img/${type}/`, formData)
    },
    getBankAccount: async(url: string) =>
        await apiInstance.get<BankAccountType>(`${url}bank/`),
    updateBankAccount: async(url: string, data: Partial<BankAccountType>) =>
        await uploadInstance.put<BankAccountType>(`${url}bank/`, objectToFormData(data)),
    createTemple: async(data: ITempleResponse) =>
        await apiInstance.post<ITempleResponse>(`${basePath}/`, data)
}

export const offeringCategoryAPI = {
    ...deleteWithUrl,
    list: async(templeId: string|number) => 
        await apiInstance.get<IOfferingCategoryResponse[]>(`${getOCUrl(templeId)}/`),
    get: async(params: ParamWithUrlType, templeId: string|number) => 
        await apiInstance.get<IOfferingCategoryResponse>(
            'url' in params ? params.url : 
            `${getOCUrl(templeId)}/${params.itemId}/`
        ),
    post: async(data: IOfferingCategory, templeId: string|number) =>
        await apiInstance.post<IOfferingCategoryResponse>(`${getOCUrl(templeId)}/`, data),
    update: async(url: string, data: Partial<IOfferingCategory>) =>
        await apiInstance.patch<IOfferingCategoryResponse>(url, data)
}

export const offeringAPI = {
    ...deleteWithUrl,
    list: async(templeId: string|number) => 
        await apiInstance.get<IOfferingResponse[]>(`${getOfferingUrl(templeId)}/`),
    get: async(params: ParamWithUrlType, templeId: string|number) => 
        await apiInstance.get<IOfferingResponse>(
            'url' in params ? params.url : 
            `${getOfferingUrl(templeId)}/${params.itemId}/`
        ),
    post: async(data: IOffering, templeId: string|number) =>
        await apiInstance.post<IOfferingResponse>(`${getOfferingUrl(templeId)}/`, data),
    update: async(url: string, data: Partial<IOffering>) =>
        await apiInstance.patch<IOfferingResponse>(url, data),
    addOfferingMeta: async(url: string, data:IOfferingMetaType) =>
        await apiInstance.patch<IOfferingResponse>(`${url}offering_meta/add/`, data),
    removeOfferingMeta: async(url: string, data:IOfferingMetaType) =>
        await apiInstance.patch<IOfferingResponse>(`${url}offering_meta/remove/`, data),
    addOfferingTime: async(url: string, data:IOfferingTime) =>
        await apiInstance.post<IOfferingTimeResponse>(`${url}offering_times/`, data),
    updateOfferingTime: async(url: string, data:Partial<IOfferingTime>) =>
        await apiInstance.patch<IOfferingTimeResponse>(url, data),
    deleteOfferingTime: async(url: string) =>
        await apiInstance.delete(url)
}

export const donationCategoryAPI ={
    ...deleteWithUrl,
    list:async(templeId:string|number) =>
        await apiInstance.get<IDonationCategoryResponse[]>(`${getDCUrl(templeId)}/`),
    get:async(params:ParamWithUrlType, templeId:string|number) =>
        await apiInstance.get<IDonationCategoryResponse>(
            `url` in params ? params.url :
            `${getDCUrl(templeId)}/${params.itemId}`
        ),
    post:async(data:IDonationCategory,templeId:string|number) =>
        await apiInstance.post<IDonationCategoryResponse>(`${getDCUrl(templeId)}/`,data),
    update:async(url:string,data:Partial<IDonationCategory>) =>
            await apiInstance.patch<IDonationCategoryResponse>(url,data),
    delete:async(url:string) =>
            await apiInstance.delete(url)
}

export const donationAPI = {
    ...deleteWithUrl,
    list:async(templeId:string|number)=> 
        await apiInstance.get<IDonationResponse[]>(`${getDonationUrl(templeId)}/`),
    get:async(params:ParamWithUrlType,templeId:string|number) =>
        await apiInstance.get<IDonationResponse>(
            'url' in params ? params.url :
            `${getDonationUrl(templeId)}/${params.itemId}/`
        ),
    post:async(data:IDonation,templeId:string|number) =>
            await apiInstance.post<IDonationResponse>(`${getDonationUrl(templeId)}/`,data),
    update:async(url:string,data:Partial<IDonation>) =>
            await apiInstance.patch<IDonationResponse>(url,data),
    delete:async(url:string) =>
            await apiInstance.delete(url),
    addDonationMeta:async(url:string,data:IDonationMetaType) => 
            await apiInstance.patch<IDonationResponse>(`${url}donation_meta/add/`,data),
    removeDonationMeta:async(url:string,data:IDonationMetaType) => 
            await apiInstance.patch<IDonationResponse>(`${url}donation_meta/remove/`,data),
}


export const deityAPI = {
    ...deleteWithUrl,
    list: async(templeId: string|number) => 
        await apiInstance.get<IDeityResponse[]>(`${getDeityUrl(templeId)}/`),
    get: async(params: ParamWithUrlType, templeId: string|number) =>
        await apiInstance.get<IDeityResponse>(
            'url' in params ? params.url : 
            `${getDeityUrl(templeId)}/${params.itemId}/`
        ),
    update: async(url: string, data: Partial<IDeityType>) =>
        await apiInstance.patch<IDeityResponse>(url, data),
    post: async(data:IDeityType, templeId: string|number) =>
        await apiInstance.post<IDeityResponse>(`${getDeityUrl(templeId)}/`, data),
    addOfferings: async(url: string, offerings: (number|string)[]) =>
        await apiInstance.patch<IDeityResponse>(`${url}offerings/add/`, offerings),
    removeOfferings: async(url: string, offerings: (number|string)[]) =>
        await apiInstance.patch<IDeityResponse>(`${url}offerings/remove/`, offerings),
}

export const orderAPI = {
    list: async(templeId?: string|number) =>
        await apiInstance.get<OrderResponseType[]>(`${getOrderUrl(templeId)}/`)
}

export const receiptAPI = {
    list: async(params: ReceiptFilterArgsType, templeId?: string|number) =>
        await apiInstance.get<ReceiptResponseType[]>(
            `${getReceiptUrl(templeId)}/`, 
            {params: configureOfferingReceiptFilterParams('list', params)}
        ),
    listWithUrl: async(url: string) =>
        await apiInstance.get<ReceiptResponseType[]>(url),
    generatePDFDownloadUrl: async(params: ReceiptFilterArgsType, templeId?: string|number) =>
        await apiInstance.get<DownloadUrlResponseType>(
            `${getReceiptUrl(templeId)}/generate_download_link`, 
            {params: configureOfferingReceiptFilterParams('pdf', params)}
        ),
    generatePriestDownloadUrl: async(params: ReceiptFilterArgsType, templeId?: string|number) =>
        await apiInstance.get<DownloadUrlResponseType>(
            `${getPriestReceiptUrl(templeId)}/generate_download_link`, 
            {params: configureOfferingReceiptFilterParams('priestPDF', params), }
        ),
    get: async(params: ParamWithUrlType, templeId?: string|number) =>
        await apiInstance.get<ReceiptResponseType>(
            'url' in params ? params.url : 
            `${getReceiptUrl(templeId)}/${params.itemId}/`
        ),
    markAsComplete: async(data: MarkAsCompleteArgsType, templeId?: string| number,) =>
        await apiInstance.patch<ReceiptResponseType[]>(`${getMarkAsCompleteUrl(templeId)}/update/`,data),
}

export const donationReceiptAPI = {
    list: async(params: DonationReceiptFilterArgsType, templeId?: string|number) =>
        await apiInstance.get<DonationReceiptResponseType[]>(
            `${getDonationReceiptUrl(templeId)}/`, 
            {params: configureDonationReceiptFilterParams('list', params)}
        ),
    listWithUrl: async(url: string) =>
        await apiInstance.get<DonationReceiptResponseType[]>(url),
    generatePDFDownloadUrl: async(params: DonationReceiptFilterArgsType, templeId?: string|number) =>
        await apiInstance.get<DownloadUrlResponseType>(
            `${getDonationReceiptUrl(templeId)}/generate_download_link`, 
            {params: configureDonationReceiptFilterParams('pdf', params)}
        ),
    get: async(params: ParamWithUrlType, templeId?: string|number) =>
        await apiInstance.get<DonationReceiptResponseType>(
            'url' in params ? params.url : 
            `${getDonationReceiptUrl(templeId)}/${params.itemId}/`
        ),
}


export const offeringFinancialApi = {
    list: async(params: OfferingFinancialFilterType, templeId?: string|number) =>
        await apiInstance.get<IOfferingFinancialResponseType>(
            `temple/t-admin/${templeId}/offering-financial/`, 
            {params: configureOfferingFinancialFilterParams('list', params)}
        ),
    // listWithUrl: async(url: string) =>
    //     await apiInstance.get<IOfferingFinancialResponseType[]>(url),
    generatePDFDownloadUrl: async(params: OfferingFinancialFilterType, templeId?: string|number) =>
        await apiInstance.get<DownloadUrlResponseType>(
            `temple/t-admin/${templeId}/offering-financial/generate_download_link`, 
            {params: configureOfferingFinancialFilterParams('pdf', params)}
        ),
    
}

export default templeAPI