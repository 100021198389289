import React, { useEffect } from 'react'
import Button from '@material-ui/core/Button'
import FormikTextField from 'components/Fields/FormikTextField'
import { FieldArray, Form, Formik, FormikHelpers } from 'formik'
import * as yup from 'yup'
import Alert from '@material-ui/lab/Alert'
import { extractError, isFieldError } from 'utils/api'
import { useIntl } from 'react-intl'
import IntlMessages from '@crema/utility/IntlMessages'
import Box from '@material-ui/core/Box'
import { IApiState } from 'store/types'
import { apiStatus, BlockPeriods, OfferingTypes } from 'shared/constants/AppEnum'
import { IOfferingFormType } from 'types/store/temple'
import MenuItem from '@material-ui/core/MenuItem'
import { capStr } from 'utils/fns'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { CremaTheme } from 'types/crema/AppContextPropsType'
import WeeklyDays from '../Bookable/WeeklyDays'
import MonthDays from '../Bookable/MonthDays'
import OfferingTimes from './OfferingTimes'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { offeringCategoryListThunk, selectCurrentTempleOfferingCategories } from 'store/reducers/temple/offeringCategorySlice'
import { IOfferingResponse, ITempleResponse } from 'types/api/temple'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import FormGroup from '@material-ui/core/FormGroup'
import Checkbox from '@material-ui/core/Checkbox'
import FullDates from '../Bookable/FullDates'
import FormikSwitchField from 'components/SwitchField/SwitchField'
import { getOfferingFormInitValues } from 'utils/temple/offering'
import PriceSplitForm from './PriceSplitForm'
import { FeeSplitNameResponseType } from 'api/report_app/report_app.response'

const useStyles = makeStyles((theme: CremaTheme) =>
    createStyles({
        formLeftPane: {
            // marginBottom: "1rem",
            [theme.breakpoints.up('sm')]: {
                marginRight: "2rem",
            },
        },
        formRightPane: {
            marginTop: "1rem",
        }
    }),
);

type FormSubmitHandleType =
    ((values: IOfferingFormType, formikHelpers: FormikHelpers<IOfferingFormType>) => void | Promise<any>) & Function

interface FormProps {
    buttonLabelId: string,
    initialValues?: IOfferingFormType | null,
    onSubmitCb: FormSubmitHandleType
    api: IApiState
    alerts: TqAlert[],
    offering?: IOfferingResponse,
    feeSplitNames: FeeSplitNameResponseType[],
    temple?: ITempleResponse | null
}
const FormOffering: React.FC<FormProps> = (props) => {
    const {
        buttonLabelId,
        initialValues,
        onSubmitCb,
        api,
        alerts,
        offering,
        feeSplitNames,
        temple
    } = props
    const offeringCategories = useAppSelector(selectCurrentTempleOfferingCategories)
    const dispatch = useAppDispatch()
    const { messages } = useIntl();
    const classes = useStyles();

    useEffect(() => {
        dispatch(offeringCategoryListThunk({}))
    }, [dispatch])

    const validationSchema = yup.object({
        name: yup
            .string()
            .required('Name is required'),
        offeringType: yup
            .string()
            .oneOf(Object.values(OfferingTypes), 'Invalid Offering Type')
            .required('Type of offering is required'),
        category: yup
            .number()
            .required('Category is required'),
        price: yup
            .number()
            .positive()
            .required('Price is required'),
        description: yup.string(),
        isRecommended: yup.boolean(),
        isCourierAvailable: yup.boolean(),
        perDayLimit: yup.number().positive(),
        blockPeriod: yup
            .string()
            .oneOf(Object.values(BlockPeriods), 'Invalid block period'),
        blockUnit: yup.number().positive(),
        isDakshinaRequired: yup.boolean().required(),
        minDakshinaAmount: yup.number(),
        repeatUnit: yup.number().positive(),
        useClosingTime: yup.boolean(),
        offeringTimes: yup.object({
            fieldFromTime: yup.date(),
            fieldToTime: yup.date(),
            blockCalcChooser: yup
                .string()
                .oneOf(['from', 'to'], 'Ivalid offering time setting'),
        }),
        offeringMeta: yup.object({
            customDates: yup.object({
                fieldDate: yup.date(),
            }),
            excludedDates: yup.object({
                fieldDate: yup.date(),
            }),
        }),
        splitEntries: yup.array().of(yup.object({
            name: yup.string().required('required'),
            value: yup.number().required('required'),
            percent: yup.number().min(0).max(100).required('required')
        })).test({
            name: 'is-sum-100',
            message: 'Sum of percentages should be 100',
            test(arr, ctx) {
                if (arr === undefined || arr.length === 0) return true
                const total = arr?.reduce((acc, curr) => acc + (curr.percent ?? 0), 0)
                return total === 100
            }
        }).optional()
    })
    return (
        <Formik
            enableReinitialize
            validateOnChange={true}
            initialValues={initialValues && offeringCategories.length > 0 ?
                initialValues : getOfferingFormInitValues(temple?.closingTime !== undefined && temple?.closingTime !== null)
            }
            validationSchema={validationSchema}
            onSubmit={onSubmitCb}
        >
            {({ isSubmitting, dirty, values, isValid, setFieldValue, errors }) => (
                <Form noValidate autoComplete='off'>
                    {console.log(isSubmitting, isValid, errors)}
                    <Grid container spacing={10}>
                        <Grid xs={12} sm={6} item>
                            <Box className={classes.formLeftPane}>
                                <FormikTextField
                                    placeholder={messages['common.name']}
                                    name='name'
                                    label={<IntlMessages id='common.name' />}
                                    variant='outlined'
                                    required
                                    fullWidth
                                />
                                <FormGroup>
                                    <FormControlLabel
                                        name='isRecommended'
                                        control={
                                            <Checkbox
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>, checked) => {
                                                    setFieldValue(event.currentTarget.name, checked)
                                                }}
                                            />
                                        }
                                        checked={Boolean(values.isRecommended)}
                                        label={<IntlMessages id='offering.recommended' />}
                                    />
                                    <FormControlLabel
                                        name='isCourierAvailable'
                                        control={
                                            <Checkbox
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>, checked) => {
                                                    setFieldValue(event.currentTarget.name, checked)
                                                }}
                                            />
                                        }
                                        checked={Boolean(values.isCourierAvailable)}
                                        label={<IntlMessages id='offering.courierAvailable' />}
                                    />
                                </FormGroup>
                                <FormikTextField
                                    select
                                    name='offeringType'
                                    label={<IntlMessages id='offering.offeringType' />}
                                    variant='outlined'
                                    required
                                    fullWidth
                                >
                                    {Object.entries(OfferingTypes).map(([key, value]) => (
                                        <MenuItem key={key} value={value}>
                                            {capStr(value)}
                                        </MenuItem>
                                    ))}
                                </FormikTextField>
                                <FormikTextField
                                    select
                                    name='category'
                                    label={<IntlMessages id='offeringCategory' />}
                                    variant='outlined'
                                    required
                                    fullWidth
                                >
                                    {offeringCategories.map(item => (
                                        <MenuItem key={item.id} value={item.id}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                                </FormikTextField>
                                <FormikTextField
                                    placeholder={messages['common.price']}
                                    name='price'
                                    label={<IntlMessages id='common.price' />}
                                    variant='outlined'
                                    // value={values.price}
                                    type='number'
                                    required
                                    fullWidth
                                />
                                {temple && <FieldArray name='splitEntries'>
                                    {(arrHelpers) => (
                                        <PriceSplitForm
                                            temple={temple}
                                            price={values.price != "" ? values.price : undefined}
                                            feeSplitNames={feeSplitNames}
                                            // splitEntries={values.splitEntries}
                                            addNewEntry={() => arrHelpers.push({ name: '', percent: '', value: '' })}
                                            removeEntry={(index) => arrHelpers.remove(index)}
                                        />)
                                    }
                                </FieldArray>}
                                <FormikTextField
                                    name="description"
                                    label={<IntlMessages id='offering.description' />}
                                    variant='outlined'
                                    fullWidth
                                    multiline
                                    rowsMax={10}
                                />
                                {temple?.closingTime && (
                                    <FormControl margin="normal" component="fieldset">
                                        <FormLabel component="legend">{messages['offering.dateRangeCalc']}</FormLabel>
                                        <RadioGroup
                                            row
                                            aria-label="use closing time"
                                            name={'useClosingTime'}
                                            value={values.useClosingTime === undefined || values.useClosingTime === true ? 'use_closing_time' : 'use_24_hr'}
                                            onChange={event => setFieldValue(event.target.name, event.target.value === 'use_closing_time' ? true : false)}
                                        >
                                            <FormControlLabel
                                                value="use_closing_time"
                                                control={<Radio />}
                                                label={messages['offering.useClosingTime']}
                                            />
                                            <FormControlLabel
                                                value="use_24_hr"
                                                control={<Radio />}
                                                label={messages['offering.use24HrClock']}
                                            />
                                        </RadioGroup>
                                    </FormControl>
                                )}
                                {values.offeringTimes.times.length === 0 && (
                                    <FormikTextField
                                        placeholder={messages['offering.perDayLimit']}
                                        name='perDayLimit'
                                        label={<IntlMessages id='offering.perDayLimit' />}
                                        variant='outlined'
                                        fullWidth
                                    />)}
                                <Divider style={{ marginTop: "2.5rem", marginBottom: "2rem" }} variant="middle" />
                                <FormikTextField
                                    select
                                    placeholder={messages['offering.blockPeriod']}
                                    name='blockPeriod'
                                    label={<IntlMessages id='offering.blockPeriod' />}
                                    variant='outlined'
                                    fullWidth
                                >
                                    {Object.entries(BlockPeriods).map(([key, value]) => (
                                        <MenuItem key={key} value={value}>
                                            {capStr(value).split('_').join(' ')}
                                        </MenuItem>
                                    ))}
                                </FormikTextField>
                                {values.blockPeriod && values.blockPeriod !== BlockPeriods.NA && (
                                    <FormikTextField
                                        placeholder={messages['offering.blockUnit']}
                                        name='blockUnit'
                                        label={<IntlMessages id='offering.blockUnit' />}
                                        variant='outlined'
                                        type='number'
                                        fullWidth
                                    />)}
                                {values.offeringType !== OfferingTypes.CUSTOM && (
                                    <FormikTextField
                                        placeholder={messages['offering.repeatUnit']}
                                        name='repeatUnit'
                                        label={<IntlMessages id='offering.repeatUnit' />}
                                        variant='outlined'
                                        type='number'
                                        fullWidth
                                    />
                                )}
                                <Divider style={{ marginTop: "2.5rem", marginBottom: "2rem" }} variant="middle" />
                                <FormikSwitchField
                                    label='Is Dakshina Required'
                                    name="isDakshinaRequired"
                                />
                                {values.isDakshinaRequired && <FormikTextField
                                    placeholder={messages['offering.minDakshinaAmount']}
                                    name='minDakshinaAmount'
                                    label={<IntlMessages id='offering.minDakshinaAmount' />}
                                    variant='outlined'
                                    type='number'
                                    fullWidth
                                />}
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Box className={classes.formRightPane}>
                                <FieldArray
                                    name={'offeringTimes.times'}
                                    render={arrayHelpers => (
                                        <OfferingTimes
                                            formContext="offeringTimes"
                                            formState={values.offeringTimes}
                                            setFieldValue={setFieldValue}
                                            errors={errors.offeringTimes}
                                            arrayHelpers={arrayHelpers}
                                            offering={offering}
                                        />
                                    )}
                                />
                                {values.offeringType === OfferingTypes.MONTHLY && (
                                    <MonthDays
                                        // selectedDays={monthDayState}
                                        // handleChange={handleOfferingMonthDay}
                                        formContext="offeringMeta.monthDays"
                                        formState={values.offeringMeta.monthDays}
                                        setFieldValue={setFieldValue}
                                    />
                                )}
                                {values.offeringType === OfferingTypes.WEEKLY && (
                                    <WeeklyDays
                                        formContext="offeringMeta.weekDays"
                                        formState={values.offeringMeta.weekDays}
                                        setFieldValue={setFieldValue}
                                    />
                                )}
                                {values.offeringType === OfferingTypes.CUSTOM ? (
                                    <FullDates
                                        titleId="offering.customOffering.action.title"
                                        formState={values.offeringMeta.customDates}
                                        dateContext="offeringMeta.customDates"
                                        setFieldValue={setFieldValue}
                                        errors={errors.offeringMeta?.customDates}
                                    // addHandler={handleCustomDateAdd}
                                    // deleteHandler={handleCustomDateDelete}
                                    // arrayHelpers={arrayHelpers}
                                    />
                                ) : (
                                    <FullDates
                                        titleId="offering.excluded"
                                        formState={values.offeringMeta.excludedDates}
                                        dateContext="offeringMeta.excludedDates"
                                        errors={errors.offeringMeta?.excludedDates}
                                        setFieldValue={setFieldValue}
                                    />
                                )}
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Button disabled={isSubmitting || !isValid} variant="contained" color="primary" type="submit">
                                <IntlMessages id={buttonLabelId} />
                            </Button>
                        </Grid>
                        <Grid item container spacing={4} direction="column" alignItems="flex-start">
                            {alerts.map((alert, index) => (
                                <Grid item key={index}>
                                    <Alert severity={alert.severity}>{alert.message}</Alert>
                                </Grid>
                            ))}
                            {api.status === apiStatus.FAILED && !isFieldError(api.error) && (
                                <Grid item>
                                    <Alert severity="error">{extractError(api.error)}</Alert>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    )
}

export default React.memo(FormOffering)